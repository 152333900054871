
 <div class="imageContainer row" #imageContainer (dragover)="dragImage($event)" (dragend)="endDrag($event)" > <!-- -->

  <div class="leftContainer  d-flex flex-column justify-content-between col-md-2">
    <div class="container d-flex flex-column justify-content-center">
      <h2 class="levelHeading">{{tower}}</h2>
      </div>
  </div>

  <div class="col-md-8 container-fluid d-flex justify-content-center imageBox" (wheel)="wheelZoom($event)">
    <div class="pan-zoom-frame">
             <div class="pan-element"  #container>
                <div class="zoom_element">
                   <div style="position: relative; width: 100%; height: 100%;">
      <ng-template #ElseImage>
    <div class="else">
        <h2>No Image Found</h2>
        </div>
      </ng-template>
                      <img [ngClass]="{'imageVisibility' : isLoaded != true}" *ngIf="imageData.length > 0;else ElseImage" #image class="image" [src]="imageData[crntDir].Payload[index]?.file" (load)="imageLoad($event)" >
                   </div>
                </div>
          </div>
    </div>
  </div>

  <div class="rightContainer col-md-2">
  <div class="container d-flex  flex-column justify-content-center align-items-center">
    <h2 class="levelHeading">Directories</h2>
    <div class="buttonGroup">
      <button mat-mini-fab class="add" *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-admin'" (click)="addDir($event)">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-mini-fab class="delete" *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-admin'" (click)="deleteSection()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <div class="viewList">
      <ng-container *ngIf="imageData.length > 0;else ElseDir;">
<table class="listDir">
  <ng-container *ngFor="let elt of imageData;let i = index">
    <tr class="crntView" [ngClass]="{ 'selectedView' : crntDir == i  }" >
      <td  class="dirText">{{ i+1 }}</td>
      <td class="dirText" (click)="crntDrty(i)">{{ elt.Directory }}</td>
      <td *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'"><mat-icon (click)="editDir($event,i,elt.Directory)" [ngClass]="{ 'selectededit' : crntDir == i  }" class="edit">edit</mat-icon></td>
      <td *ngIf="(role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing') && mode == 'Delete'"><span><input type="checkbox" (change)="deleteItems($event,elt.Directory,i)"></span></td>
    </tr>
       </ng-container>
</table>
      </ng-container>
      <ng-template #ElseDir>
    <div class="else">
        <h3>No Directory Found</h3>
        </div>
      </ng-template>
    </div>
      </div>
  </div>
  </div>
