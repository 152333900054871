<div class="navBar">
  <app-navbar [main]="false"></app-navbar>
</div>


<div class="orthomap" >
  <div class="orthomapTab">
    <agm-map *ngIf="isLoaded" [minZoom]="minZoom" [latitude]="latLong1.lat" [longitude]="latLong1.lng" #mapContainer [maxZoom]="maxZoom" [zoom]="zoom" (mapReady)="onMapReady($event)" (mapClick)="addMarker($event)" [mapTypeControl]="mapTypeControl" [streetViewControl]="false" [disableDefaultUI]="disableUI">

      <agm-marker *ngFor="let marker of markers;" [latitude]="marker.lat" [longitude]="marker.lng" [iconUrl]="$any(icon)" (markerClick)="removeMarker($event)"></agm-marker>
      <agm-polyline #polyline [visible]="true" [strokeWeight]="2" [strokeColor]="'#e40017'">
        <agm-polyline-point *ngFor="let marker of markers; let i=index" [latitude]="marker.lat" [longitude]="marker.lng"></agm-polyline-point>
      </agm-polyline>
       <agm-info-window *ngFor="let window of windows;" [isOpen]="true" [latitude]="window.lat" [longitude]="window.lng" [closeWhenOthersOpen]="false">
          {{ window.distance }} m
      </agm-info-window>
    </agm-map>
    <div class="buttons editOrtho" *ngIf="role == 'aero-processing' || role == 'super-admin'">
      <button mat-raised-button color="primary" (click)="editOrtho()">Edit</button>
    </div>
    <mat-card>
      <mat-slide-toggle color="primary" [checked]="addAnnotation ? true : false" (change)="toggle('add')">Add Annotations</mat-slide-toggle>
      <mat-slide-toggle [checked]="removeAnnotation ? true : false" (change)="toggle('remove')">Remove Markers</mat-slide-toggle>
      <div class="Modes">
        <label class="label">Modes:</label>
        <mat-radio-group [(ngModel)]="mode" aria-label="Select an Mode" (change)="changeMode($event)">
          <mat-radio-button [value]="1" checked>Distance</mat-radio-button>
          <mat-radio-button [value]="2">Area</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="select">
        <mat-form-field appearance="fill">
          <mat-label>Choose an Opacity</mat-label>
          <mat-select [(value)]="opacity" (selectionChange)="changeOpacity()">
            <mat-option [value]="100">100 %</mat-option>
            <mat-option [value]="80">80 %</mat-option>
            <mat-option [value]="60">60 %</mat-option>
            <mat-option [value]="40">40 %</mat-option>
            <mat-option [value]="20">20 %</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    <div class="buttons">
      <button mat-raised-button color="primary" (click)="printing()">Capture</button>
      <button mat-raised-button color="accent" (click)="clear()">Clear</button>
    </div>
    </mat-card>
    <mat-card class="result">
      <mat-label #result>
        <span *ngIf="mode == 1"> Distance : {{ value }} m </span>
        <span *ngIf="mode == 2"> Area : {{ value }} m <sup>2</sup></span>
      </mat-label>
    </mat-card>
  </div>


  </div>



