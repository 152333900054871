
<div class="navBar">
  <app-navbar [currentRoute]="'users'" [crntYear]="crntYear" [data]="data" [main]="true"></app-navbar>
</div>

<div class="users">
  <div class="usersTab">
    <div class="usersContainer">
      <div class="options">
        <div class="selectContainer">
        <select class="roles" [(ngModel)]="crntRole" (change)="applyFilter(true)">
            <option readonly disabled>Select Role</option>
            <option [value]="'user'">User</option>
            <option [value]="'telstra-processing'">Telstra Processing</option>
            <option [value]="'aero-Processing'">Aero Processing</option>
            <option [value]="'pilot'">Pilot</option>
            <option [value]="''" selected>All</option>
          </select>
        </div>
        <div class="filterContainer">
        <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter(false)" placeholder="Ex. Mia" #filter [(ngModel)]="filterValue">
          </mat-form-field>
        </div>
        <div class="addUserContainer">
          <button mat-mini-fab color="primary" [routerLink]="'/sign-up'">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
      <div class="mat-elevation-z8">
        <table class="tableContainer" matSortDirection="asc" mat-table [dataSource]="dataSource" matSort>

          <!-- No Column -->
          <ng-container matColumnDef="No">
            <th mat-header-cell *matHeaderCellDef> No. </th>
            <td mat-cell  *matCellDef="let i = index"> {{i+1}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="UserName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell  *matCellDef="let element"> {{ element.displayName }} </td>
          </ng-container>

          <ng-container matColumnDef="UserNameEdit">
            <th mat-header-cell *matHeaderCellDef > Edit </th>
            <td mat-cell  *matCellDef="let element">  <i class="edit" (click)="editEvent($event,element.uid,'displayName',element.displayName)"> <mat-icon class="editIcon">edit</mat-icon></i></td>
          </ng-container>

          <!-- Email Column -->
          <ng-container matColumnDef="Email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
            <td mat-cell   *matCellDef="let element"  > {{element.email}} </td>
          </ng-container>

          <!-- Role Column -->
          <ng-container matColumnDef="Role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
            <td mat-cell   *matCellDef="let element"  > {{element.role }} </td>
          </ng-container>


          <ng-container matColumnDef="RoleEdit">
            <th mat-header-cell *matHeaderCellDef> Edit </th>
            <td mat-cell   *matCellDef="let element"  > <i *ngIf="element.role != 'super-admin'" class="edit" (click)="editEvent($event,element.uid,'role',element.role)"> <mat-icon class="editIcon">edit</mat-icon></i> </td>
          </ng-container>

          <ng-container matColumnDef="Delete">
            <th mat-header-cell *matHeaderCellDef> Delete </th>
            <td mat-cell   *matCellDef="let element"  > <i *ngIf="element.role != 'super-admin'" class="edit" (click)="deleteEvent($event,element.uid)"> <mat-icon class="deleteIcon">delete</mat-icon></i> </td>
          </ng-container>



          <tr mat-header-row class="rowBack" *matHeaderRowDef="displayedColumns;"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10]"></mat-paginator>
      </div>
    </div>
  </div>
</div>
