import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinModelServiceService } from '../spin-model-service.service';
import { HttpClient } from '@angular/common/http';
import * as S3 from 'aws-sdk/clients/s3';


@Component({
  selector: 'app-dialog-upload',
  templateUrl: './dialog-upload.component.html',
  styleUrls: ['./dialog-upload.component.css']
})
export class DialogUploadComponent implements OnInit {


  s3: any;
  projectSetting: any;
  direclinks = []
  fulldireclinks = {}
  permdireclinks = []
  towerName;
  year;
  isUploading = false;

  constructor(
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private service: SpinModelServiceService,private http: HttpClient,private afs: AngularFirestore,@Inject(MAT_DIALOG_DATA) public data: {towerName: string,year: string}) { 
      this.towerName = this.data.towerName
      this.year = this.data.year
      this.loadData()
    }

  ngOnInit(): void {
  }

  closeDialog(){
    this.dialog.closeAll()
  }

  async loadData(){
    let resLinks = await this.service.getS3()
    this.permdireclinks = resLinks.keyNames
    this.direclinks = resLinks.keyNames
    this.fulldireclinks = resLinks.fullPath
    console.log(this.fulldireclinks)
  }

  uploadSpinView(link){
    this.isUploading = true
    let folderName = link
    let twLinkArrs = this.fulldireclinks[link]
    console.log("link-",twLinkArrs)
    const formData = new FormData();
    formData.append('towerName', this.towerName)
    formData.append('year', this.year)
    formData.append('foldername', folderName)
    formData.append('twLinkArrs', twLinkArrs)
    formData.append('spinMode', 'Telstra')
    console.log('towerName: ', this.towerName)
    console.log('year: ', this.year)
    console.log('folderName: ', folderName)
    console.log('twLinkArrs: ', twLinkArrs)
    this.http.post('https://api1.phicode.co/spinUpload', formData, {responseType: 'json'}).subscribe((res: any) => {
      // console.log(res)
      if(res.status == "success"){
        this.dialog.closeAll()
        this.isUploading = false
      }
      else if(res.status == "error"){
        this.isUploading = false
        this.snackBar.open('Tower view processing failed,Please Try again', 'close', {
          duration: 2000,
        });
      }
    });
  }

  searchTowerViewFiles(value){
    if(value != '')
    {
      this.direclinks = this.permdireclinks.filter((val) => {
        return val.startsWith(value.toUpperCase())
      })
    }
    else{
      this.direclinks = this.permdireclinks
    }
    }
}
