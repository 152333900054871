<h1 mat-dialog-title>Add Tower</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Tower Name</mat-label>
    <input matInput type="text" [(ngModel)]="Tower" required>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Site ID</mat-label>
    <input matInput type="text" [(ngModel)]="SiteID" required>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Region</mat-label>
    <input matInput type="text" [(ngModel)]="Region" required>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Status</mat-label>
    <mat-select [(ngModel)]="Status" required>
      <mat-option [value]="'Inspected'">Inspected</mat-option>
      <mat-option [value]="'Uninspected'">Uninspected</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Structure Type</mat-label>
    <mat-select [(ngModel)]="StructureType" required>
      <mat-option [value]="'Yes'">N/A</mat-option>
      <mat-option [value]="'Tower'">Tower</mat-option>
      <mat-option [value]="'Mast'">Mast</mat-option>
      <mat-option [value]="'Pole'">Pole</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
  <mat-label>Latitude</mat-label>
  <input matInput type="text" [(ngModel)]="Latitude" required>
</mat-form-field>
<mat-form-field>
<mat-label>Longitude</mat-label>
<input matInput type="text" [(ngModel)]="Longitude" required>
</mat-form-field>
<mat-form-field>
  <mat-label>Completed</mat-label>
  <mat-select [(ngModel)]="Completed" required>
    <mat-option [value]="'Yes'">Yes</mat-option>
    <mat-option [value]="'No'">No</mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field>
  <mat-label>Type</mat-label>
  <input matInput type="text" [(ngModel)]="Type" required>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Corrosion</mat-label>
    <mat-select [(ngModel)]="Corrosion" required>
      <mat-option [value]="'N/A'">N/A</mat-option>
      <mat-option [value]="'Heavy'">Heavy</mat-option>
      <mat-option [value]="'Medium'">Medium</mat-option>
      <mat-option [value]="'Light'">Light</mat-option>
      <mat-option [value]="'Surface'">Surface</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Canrad Variation</mat-label>
    <mat-select [(ngModel)]="CanradVariation" required>
      <mat-option [value]="'N/A'">N/A</mat-option>
      <mat-option [value]="'Yes'">Yes</mat-option>
      <mat-option [value]="'No'">No</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-raised-button (click)="addTowerFn()" cdkFocusInitial class="submit">Ok</button>
</div>
