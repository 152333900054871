import { Component, OnInit, Input, Output, EventEmitter,OnChanges } from '@angular/core';
import { AuditFetchService } from '../audit-fetch.service';
@Component({
  selector: 'app-audit-details',
  templateUrl: './audit-details.component.html',
  styleUrls: ['./audit-details.component.css']
})
export class AuditDetailsComponent implements OnInit,OnChanges {

  @Input() data : Object;

  @Input() item : Object;

  @Input() role: String;
  deleteIndex = [];
  mode = null;


  @Output() imageEvent = new EventEmitter<any>();


  @Output() editEvent = new EventEmitter<any>();


  @Output() addDeleteEvent = new EventEmitter<any>();

  selected : String;

  ngOnChanges() {
    if(this.item == 'General') {
      this.selected = "General";
      return;
  }
    if(this.item == 'Equipment') {
      this.selected = "Equipment";
      return;
    }
     this.selected = "Details";
     return;
  }


  constructor(private audit: AuditFetchService) { }

  ngOnInit(): void {
    this.selected = "General";
  }

  selectDetails(event) {
    let chip = event.target;
    this.selected = chip.getAttribute("value");
    }

    mmc(item){
      return item.Option !== 1;
    }

    sendImage(src : String){
      this.imageEvent.emit(src);
    }


    editData(key,value,section,detailIndex,sectionIndex,bool,sectionName = null){
      this.editEvent.emit({key,value,section,detailIndex,sectionIndex,bool,sectionName});
    }

    addSection(){
      this.addDeleteEvent.emit({ mode : 'Add' , add: {sectionIndex : this.item['sectionIndex'], section :  this.item['section'] }});
    }

    deleteItems(event,name ,section, detailIndex, sectionIndex){
      if(event.target.checked){
        this.deleteIndex.push({name,detailIndex , section,  sectionIndex});
      }else{
      this.deleteIndex = this.deleteIndex.filter(elt =>{
        return (elt.name != name && elt.detailIndex != detailIndex && elt.sectionIndex == sectionIndex || (elt.detailIndex == detailIndex && elt.sectionIndex != sectionIndex));//
      });
      }
    }


    deleteSection(){
      if(this.mode == 'Delete'){
        if(this.deleteIndex.length > 0){
       this.addDeleteEvent.emit({delete: this.deleteIndex, mode : 'Delete'});
        }
        this.deleteIndex = [];
      this.mode = null;
    }else{
      this.mode = 'Delete';
      this.deleteIndex = [];
    }
    }

    // remove all '\t' from all strings if there
    async getJSON() {
      console.log('JSON:');
      let generalDataList = this.audit.audit_data[0];
      let equipmentDataList = this.audit.audit_data[1];
      console.log('generalDataList:', generalDataList);
      console.log('equipmentDataList:', equipmentDataList);
  
      let fullJsonOutput = {}
      let overallGenDetailsOutput = []
      let overallEquipDetailsOutput = [];
  
      for (let index = 0; index < generalDataList.length; index++) {
        let generalDataListItm = generalDataList[index];
        let generalDataListItmDetails = generalDataList[index].Details;
        // console.log('generalDataListItm - ', generalDataListItm);
  
        
        let tmpGenItemObj = {}
        tmpGenItemObj['Section'] = generalDataListItm.Name;
        tmpGenItemObj['Details'] = [];
        
        for (let index2 = 0; index2 < generalDataListItmDetails.length; index2++) {
          let tmpIndiItem = {}
          tmpIndiItem['Item'] = generalDataListItmDetails[index2].Name;
          tmpIndiItem['Description'] = generalDataListItmDetails[index2].Value;
          tmpGenItemObj['Details'].push(tmpIndiItem)
        }
        
        overallGenDetailsOutput.push(tmpGenItemObj)
      }
  
      fullJsonOutput['GeneralTableData'] = overallGenDetailsOutput
  
      if(equipmentDataList.length > 0){
        for (let index3 = 0; index3 < equipmentDataList.length; index3++) {
          let equipDataListItmDetails = equipmentDataList[index3].Details;
          
          let tmpEquipItemObj = {}
          tmpEquipItemObj['EquipmentReference'] = equipmentDataList[index3].Name;
          tmpEquipItemObj['Details'] = [];
          
          for (let index4 = 0; index4 < equipDataListItmDetails.length; index4++) {
            let tmpIndiItem = {}
            tmpIndiItem['Item'] = equipDataListItmDetails[index4].Name;
            tmpIndiItem['Description'] = equipDataListItmDetails[index4].Value;
            tmpEquipItemObj['Details'].push(tmpIndiItem)
          }
          
          overallEquipDetailsOutput.push(tmpEquipItemObj)
        }
  
      }
  
  
      fullJsonOutput['EquipmentTableData'] = overallEquipDetailsOutput
  
      console.log('fullJsonOutput:', fullJsonOutput);
  
      let downloadFileNm = 'json_data_'+generalDataList[1].Details[0].Value+'.json'
      download(downloadFileNm, JSON.stringify(fullJsonOutput))
    }
}

function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}