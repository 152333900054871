import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NavbarFetchService } from './navbar-fetch.service'
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from './../../auth.service';
import { SearchComponent } from './../search/search.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  result: Object = {};
  tower;
  towerName;
  selectedTower;
  isLoaded : Boolean =  false;
  yearArr = ['2021','2022','2023']
  @Input() main;
  @Input() currentRoute;
  @Input() crntYear = 0;
  @Input() data = [];
  totalData = []

  @ViewChild('searchBox') searchBox;
  role = null;
  year;
  value;
 constructor(private snackBar: MatSnackBar,private service: NavbarFetchService,private router : Router,private route : ActivatedRoute,private authService : AuthService, private dialog: MatDialog) { }  //private route: ActivatedRoute

  ngOnInit(): void {
    if (this.router.url.search("aero-processing") == 1) {
      this.role = "aero-processing";
    }
    if(this.router.url.search("user") == 1){
      this.role = 'user';
    }
    if(this.router.url.search("pilot") == 1){
      this.role = 'pilot';
    }
    if(this.router.url.search("super-admin") == 1){
      this.role = 'super-admin';
    }
    if(this.router.url.search("telstra-processing") == 1){
      this.role = 'telstra-processing';
    }
    if(!this.main){
    this.route.params.subscribe(params =>{
      this.year = params.year;
      this.towerName = params.tower;
      if(this.towerName){
        this.service.fetchData(this.towerName).forEach(data=>{
          let temp = [];
          data.forEach(doc=>{
          temp.push(doc.data());
          })
          this.result = temp[0].Audit["General"];
        }).then(()=>{
          this.isLoaded = true;
        })
      }
    })
    }
  }

  ngAfterViewInit(){
    for(let i = 0; i < this.yearArr.length;i++){
      this.service.fetchTowers('TOWERS' + "/" + this.yearArr[i] + "/" + this.yearArr[i]).forEach(data => {
        let towerList = []
        data.forEach(doc => {
          let data: any = doc.data()
          towerList = [...towerList,data] 
        })
        this.totalData[i] = {Towers:towerList, Year: this.yearArr[i]};
      }).catch((err) => {
        this.snackBar.open(err, "close", { duration: 2000 });
      })
    }
  }

  logout(){
    this.authService.SignOut();
    localStorage.removeItem('creds')
  }

  inspectedTower(event){
    this.router.navigate(['/'+this.role+'/'+ this.data[this.crntYear].Year  +'/' + event.target.value.replaceAll(' ','')+ '/summary']);
  }

  uninspectedTower(event){
    this.router.navigate(['/'+this.role+'/'+ this.data[this.crntYear].Year  +'/' + event.target.value.replaceAll(' ','')+ '/upload']);
  }

  stopEvent(event){
    if(event.which == 13){
      event.preventDefault();
    }
  }


  search(event){
    const dialogRef = this.dialog.open(SearchComponent, {
      width: '450px',
      maxHeight: '480px',
      data: { value : this.totalData}
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        this.value = result;
      },
      err => {
        this.snackBar.open(err, "close", { duration: 2000 });
      },
      () => {
        if(this.value){
          this.router.navigate(['/'+this.role+'/'+ this.value.year +'/' +this.value.tower+ '/summary']);
        }
      })
  }


}
