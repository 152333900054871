import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class AuditFetchService {
  count = 0;
  auditData = [];
  audit_data = [];
  audit_data1 = [];
  constructor(private fireStore : AngularFirestore) { }


  fetchData(towerName){
    return this.fireStore.collection(towerName).get();
}



 setData(tower){
 return  this.fireStore.collection(tower).doc(tower)
 }

 towersData(year){
   return this.fireStore.collection('towers').doc(year);
 }

 dataBuild(data, data1){
  this.audit_data.push(data, data1);
  this.audit_data1= data1;
  // console.log(this.audit_data);
  
 }

}
