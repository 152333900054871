import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { MatPaginator } from '@angular/material/paginator';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import {MatSortModule} from '@angular/material/sort';
import {MatSort} from '@angular/material/sort';
import {BehaviorSubject} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadService } from './service/upload.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Location} from '@angular/common';

export interface info {
  project: string;
  asset : string;
  vertikaliti: string;
  dateCreated: string;
  totalImage: number;
  status: string
}

@Component({
  selector: 'app-orthofilelisting',
  templateUrl: './orthofilelisting.component.html',
  styleUrls: ['./orthofilelisting.component.css']
})
export class OrthofilelistingComponent implements OnInit {

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  pageNumber: any;
  towerName: any;
  year: any;
  siteID: any;
  progress: number = 0;
  fileUpload: string = 'block'
  prg: string = 'none'
  file: any = {}
  rWidth: any;
  rHeight: any;
  tbDisplay: any = "";
  upDisplay: any = "";
  pix: any = [];
  displayPixData: any = [];
  role: any;

  dataSource: MatTableDataSource<info> = new MatTableDataSource<info>();
  columnsToDisplay = ['index','project', 'asset', 'vertikaliti', 'dateCreated', 'totalImage', 'status'];
  dataSubject = new BehaviorSubject<Element[]>([]);

  pixSites = [ 'pix4d','pix4d2']

  isLoaded = true

  constructor(private snackBar: MatSnackBar,private apiService: UploadService,private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,private http: HttpClient,
    private router: Router,private route: ActivatedRoute,private _location: Location) {
      this.getData()
      this.role = router.url.trim().split("/")[1]
      this.matIconRegistry.addSvgIcon(
        `filterIcon`,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`https://image.flaticon.com/icons/svg/1159/1159641.svg`)
      );
      // Sort Icon
      this.matIconRegistry.addSvgIcon(
        `sortIcon`,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`https://image.flaticon.com/icons/svg/25/25756.svg`)
      );
      for(let i = 0;i < this.pixSites.length;i++){
        this.http.get(`https://api.${this.pixSites[i]}.vertikaliti.com/process`, {responseType: 'json'}).subscribe((res: any) => {
          const result = res.map((obj: any) => ({ ...obj, PixSite: this.pixSites[i] }))
          const fnresult = result.filter((obj) => {
            return obj.algos == 'ORTHO'
          })
          this.dataSource.data = [ ...this.dataSource.data,...fnresult]
          console.log(res)
          setTimeout(() => this.dataSource.sort = this.sort);
          setTimeout(() => this.dataSource.paginator = this.paginator);
          this.pix[i] =  result
          if(i == this.pixSites.length-1)
          {
            this.displayPixData = this.pix
            setTimeout(() => this.isLoaded = false);
          }
        })
      }
     }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator
    this.dataSource.filterPredicate = (data: info, filter: string) => {
      console.log(data)
      return data.asset.toLowerCase().includes(filter.toLowerCase());
     };
  }

  getImagesCount(images: any){
    return JSON.parse(images).length
}

//send tif file link to an api for converting to am displayable format of image
getTiffFile(details: any){
  this.upDisplay = "dn"
  this.tbDisplay = "vh"
  if(this.prg != 'block')
  {
   this.prg = 'block'
  }
  const formData = new FormData();
  formData.append('filename', details.asset)
  formData.append('s3link',`https://aerodyne-${details.PixSite}.s3.ap-southeast-1.amazonaws.com/results/${details.id}/3_dsm_ortho/2_mosaic/${details.id}_transparent_mosaic_group1.tif`)
  if(details.algos == 'ORTHO' && details.status == 'END_WHOLE_PIX4DENGINE_PROCESS'){
    this.http.post('https://api1.phicode.co/tifProgress', formData, {responseType: 'json'}).subscribe((res: any) => {
      console.log(res)
      if(res.status == "success"){
         this.apiService.rWidth = res.data.rwidth
         this.apiService.rHeight = res.data.rheight
         this.router.navigate([this.role + '/' + this.year + '/' + this.towerName + '/processOrtho', res.data.filename],{ queryParams: { rWidth: res.data.rwidth, rHeight:res.data.rheight } })
      }
      else if(res.status == "error"){
        this.prg = 'none'
        this.upDisplay = ""
        this.tbDisplay = ""
        this.snackBar.open('ortho processing failed,Please Try again', 'close', {
          duration: 2000,
        });
      }
    });
  }
  else{
    this.openSnackBar("Select Ortho based file with status END_WHOLE_PIX4DENGINE_PROCESS","Close")
  }
}

applyFilter(event: any){
   let searchText = event.target.value
   console.log(event)
   this.dataSource.filter = searchText;
}

goToPage() {
  this.paginator.pageIndex = this.pageNumber, // number of the page you want to jump.
    this.paginator.page.next({
      pageIndex: this.pageNumber,
      pageSize: this.paginator.pageSize,
      length: this.paginator.length
    });
}

//select tif file from local
async onSelect(event: any) {
  this.tbDisplay = "vh"
  this.upDisplay = "dn"
  console.log(event.target.files[0]);
  this.file = event.target.files[0]
  this.fileUpload = 'none'
  this.prg = 'block'
  const chunkSize = 1000000;
  const chunktotalcount = this.file.size / chunkSize
  const toProgressCount = 100 / chunktotalcount
  var index = 0
  for( let offset = 0; offset < this.file.size; offset += chunkSize ){
        const chunk = this.file.slice( offset, offset + chunkSize );
        await this.apiService.sendChunk( chunk, offset,index,chunktotalcount,this.file.name).then((res: any) => {
          console.log(res)
          if(res.status == 'success')
          {
             this.prg = 'none'
             console.log('Navigate to the next page')
             this.apiService.rWidth = res.width
             this.apiService.rHeight = res.height
             this.router.navigate(['/' + this.role + '/' + this.year + '/' + this.towerName + '/processOrtho',  res.imageUrl],{ queryParams: { rWidth: res.width, rHeight:res.height }});
             this.progress += toProgressCount
          }
          else if(res.status == 'progress'){
             this.progress += toProgressCount
          }
        })
        index++
       
  }
 
}

openSnackBar(message: string, action: string) {
  this.snackBar.open(message, action, {
    duration: 2000,
  });
}

getData(){
  this.route.params.subscribe(params => {
    console.log(params);
    this.towerName = params.tower;
    this.year = params.year;
  });
}

checkValue(checked,site){
  if(site == 'pix4d'){
      if(checked){
          this.displayPixData.splice(0,0,this.pix[0])
          this.dataSource.data = [...this.displayPixData]
      }
      else{
        this.displayPixData.splice(0,1)
        this.dataSource.data = [...this.displayPixData]
      }
  }
  if(site == 'pix4d2'){
    if(checked){
      this.displayPixData.splice(1,0,this.pix[1])
      this.dataSource.data = [...this.displayPixData]
  }
  else{
    this.displayPixData.splice(1,1)
    this.dataSource.data = [...this.displayPixData]
  }
  }
  if(site == 'pix4d3'){
    if(checked){
      this.displayPixData.splice(2,0,this.pix[2])
      this.dataSource.data = [...this.displayPixData]
  }
  else{
    this.displayPixData.splice(2,1)
    this.dataSource.data = [...this.displayPixData]
  }
  }
  if(site == 'pix4d4'){
    if(checked){
      this.displayPixData.splice(3,0,this.pix[3])
      this.dataSource.data = [...this.displayPixData]
  }
  else{
    this.displayPixData.splice(3,1)
    this.dataSource.data = [...this.displayPixData]
  }
  }
}

goBack(){
  this._location.back();
}

}
