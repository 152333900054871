<div class="file-manager-wrapper">
    <button type="button" class="cls-btn" (click)="closeDialog()">X</button>
      <div class="file-manager-content">
        <div class="file-manager-content-header">
          <h3>Select amplitel files listed below</h3>
        </div>
          <div class="file-manager-content-body">
            <div class="bufferprogress"><mat-progress-spinner mode="indeterminate" *ngIf="direclinks.length == 0" ></mat-progress-spinner></div>
            <div class="search-tower" *ngIf="!isUploading">
              <input class="form-control" type="text" name="search"(keyup)="searchTowerViewFiles($event.target.value)" style="border-color:#2375c7" autocomplete="off" placeholder="Start searching for a tower here...">
            </div>
            <mat-nav-list *ngIf="!isUploading">
              <a mat-list-item *ngFor="let link of direclinks" (click)="uploadData(link)"> {{ link }} </a>
           </mat-nav-list>
           <div *ngIf="isUploading" style="width:100%;"> 
            <div style="text-align: center;"><h3>Amplitel Data being processed...Please wait...</h3></div>
            <mat-progress-bar mode="buffer"></mat-progress-bar>
            </div>
          </div>
      </div>
  </div>