<div class="navBar">
  <app-navbar [currentRoute]="'table'" [crntYear]="crntYear" [data]="data" [main]="true"></app-navbar>
</div>
<div class="table">
  <div class="tableTabs" *ngIf="isLoaded">
    <div class="options">
      <div class="selectContainer">
        <select class="year" [(ngModel)]="crntYear" (change)="switchTable()">
          <option readonly disabled>Select a Year</option>
          <option *ngFor="let item of year;let i = index;" [value]="i">{{ item }}</option>
        </select>
      </div>
      <div class="statusContainer">
        <select class="year" (change)="switchStatus($event)" (focus)="setupFilter('Status')">
          <option readonly disabled>Select a Status</option>
          <option [value]="'Inspected'" selected>Inspected</option>
          <option [value]="'Uninspected'">Uninspected</option>
          <option [value]="''">All</option>
        </select>
      </div>
      <div class="filterProcessedStatus">
        <mat-form-field appearance="fill" >
          <mat-label>Processed Status</mat-label>
          <mat-select (selectionChange)="applyProcessedStatusFilter($event.value)" (focus)="setupFilter('processed')">
            <mat-option *ngFor="let prstatus of processedStatus" [value]="prstatus">
              {{prstatus}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="filterContainer">
        <mat-form-field>
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Tower" #filter [(ngModel)]="selectedStatus">
        </mat-form-field>
      </div>
      <div class="deleteContainer" *ngIf="role == 'super-admin' || role == 'aero-processing'">
        <div>
          <button *ngIf="role == 'super-admin' || role == 'aero-processing'" (click)="editTower(null,null,'add',$event)" mat-mini-fab color="accent">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
      <div class="downloadContainer">
      <button class="download summaries" (click)="downloadCSV('Summaries')"><i class="fas fa-file"></i> Download Excel Summaries</button>
      </div>
      <div class="downloadContainer">
        <button class="download summaries" style="position:relative;display:flex;" (click)="openProcessedDialog()"><mat-progress-spinner *ngIf="isProcessing" mode="indeterminate" [diameter]="15"></mat-progress-spinner><div>Update Processed Status</div></button>
      </div>
    </div>
    <div class="mat-elevation-z8">
      <table class="tableContainer" matSort matSortActive="Status" mat-table [dataSource]="dataSource" >

        <!-- Position Column -->
        <ng-container matColumnDef="No">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
          <td mat-cell  *matCellDef="let i = index"> {{pagin.pageIndex * pagin.pageSize + i + 1}} </td>
          <td mat-footer-cell *matFooterCellDef>  </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="Tower">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Tower </th>
          <!-- <td mat-cell  *matCellDef="let element"> {{element.Tower.replace(' ','')}} <i *ngIf="role == 'super-admin'" class="edit" (click)="clickEvent(element.Tower,$event,true,element.SiteID,'Tower')"> <mat-icon class="editIcon">edit</mat-icon></i></td> -->
          <td mat-cell  *matCellDef="let element"> {{element.Tower}} </td>
          <td mat-footer-cell *matFooterCellDef>  </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="TowerType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Tower Type </th>
          <td mat-cell   *matCellDef="let element"  > {{element.Type}} <i class="edit" *ngIf="role == 'super-admin'" (click)="clickEvent(element.Type,$event,true,element.SiteID,'Type', element)"> <mat-icon class="editIcon">edit</mat-icon></i></td>
          <td mat-footer-cell *matFooterCellDef>  </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="SiteID">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> SiteID </th>
          <td mat-cell   *matCellDef="let element"  > {{element.SiteID}} <i class="edit" *ngIf="false" (click)="clickEvent(element.SiteID,$event,true,element.SiteID,'SiteID', element)"> <mat-icon class="editIcon">edit</mat-icon></i></td>
          <td mat-footer-cell *matFooterCellDef> Total </td>
        </ng-container>

        <ng-container matColumnDef="Region">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Region </th>
          <td mat-cell   *matCellDef="let element"  > {{element.Region}} <i class="edit" *ngIf="role == 'super-admin'" (click)="clickEvent(element.Region,$event,true,element.SiteID,'Region', element)"> <mat-icon class="editIcon">edit</mat-icon></i></td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell   *matCellDef="let element"  > {{element.Status}} <i class="edit"  *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-admin'" (click)="clickEvent(element.Status,$event,true,element.SiteID,'Status', element)"> <mat-icon class="editIcon">edit</mat-icon></i></td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="Completed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Completed </th>
          <td mat-cell   *matCellDef="let element"  > {{element.Completed}} <i class="edit"  *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-admin'" (click)="clickEvent(element,$event,true,element.SiteID,'Completed', element)"> <mat-icon class="editIcon">edit</mat-icon></i></td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="Latitude">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Latitude </th>
          <td mat-cell   *matCellDef="let element"  > {{element.Latitude}} <i class="edit" *ngIf="role == 'super-admin'" (click)="clickEvent(element.Latitude,$event,true,element.SiteID,'Latitude', element)"> <mat-icon class="editIcon">edit</mat-icon></i></td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="Longitude">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Longitude </th>
          <td mat-cell   *matCellDef="let element"  > {{element.Longitude}} <i class="edit" *ngIf="role == 'super-admin'" (click)="clickEvent(element.Longitude,$event,true,element.SiteID,'Longitude', element)"> <mat-icon class="editIcon">edit</mat-icon></i></td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="Corrosion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Corrosion </th>
          <td mat-cell   *matCellDef="let element"  > {{element.Corrosion}} <i class="edit"  *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-admin'" (click)="clickEvent(element.Corrosion,$event,true,element.SiteID,'Corrosion', element)"> <mat-icon class="editIcon">edit</mat-icon></i></td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>



        <ng-container matColumnDef="StructureType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Structure Type </th>
          <td mat-cell   *matCellDef="let element"  > {{element.StructureType}} <i class="edit"  *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-admin'" (click)="clickEvent(element.StructureType,$event,true,element.SiteID,'StructureType', element)"> <mat-icon class="editIcon">edit</mat-icon></i></td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="CanradVariation">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Canrad Variation </th>
          <td mat-cell   *matCellDef="let element"  > {{element.CanradVariation}} <i class="edit"  *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-admin'" (click)="clickEvent(element.CanradVariation,$event,true,element.SiteID,'CanradVariation', element)"> <mat-icon class="editIcon">edit</mat-icon></i></td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="Processed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Processed </th>
           <td mat-cell  *matCellDef="let element" title="T - TowerView,D - DigitalTwin,O - OrthoMap,N/A - Not updated,'-' - No Data"> {{element.processed}} </td>
           <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <ng-container matColumnDef="Emergency">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Emergency </th>
           <td mat-cell class="numberCells"  *matCellDef="let element"  >  <span class='critical'>{{element.Emergency }}</span> </td>
           <td mat-footer-cell class="numberCells" *matFooterCellDef> <span class="critical">{{ getTotal("Emergency") }}</span> </td>
        </ng-container>

        <ng-container matColumnDef="Urgent">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Urgent </th>
          <td mat-cell class="numberCells" *matCellDef="let element"> <span class='major'>{{element.Urgent }}</span> </td>
          <td mat-footer-cell class="numberCells" *matFooterCellDef> <span class="major">{{ getTotal("Urgent") }}</span> </td>
        </ng-container>

        <ng-container matColumnDef="Planned">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Planned </th>
          <td mat-cell class="numberCells"  *matCellDef="let element"  > <span class="minor">{{element.Planned }}</span> </td>
          <td mat-footer-cell class="numberCells" *matFooterCellDef> <span class="minor">{{ getTotal("Planned") }}</span> </td>
        </ng-container>

        <ng-container matColumnDef="Monitor">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Monitor </th>
          <td mat-cell class="numberCells"  *matCellDef="let element"  > <span class="without">{{element.Monitor }}</span> &nbsp;&nbsp;
            <button *ngIf="role === 'super-admin'" (click)="editTower(element.Tower,element.SiteID,'delete',$event)" mat-mini-fab color="warn">
              <mat-icon class="deleteIcon">delete</mat-icon>
            </button>
            </td>
          <td mat-footer-cell class="numberCells" *matFooterCellDef> <span class="without">{{ getTotal("Monitor") }}</span> </td>
        </ng-container>


        <tr mat-header-row class="rowBack" *matHeaderRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="clickEvent(row.Tower,$event,false,row.Status,row.Completed, row)"></tr>
        <tr mat-footer-row class="rowBack" *matFooterRowDef="displayedColumns; "></tr>
      </table>
      <mat-paginator #pagin [pageSize]="10" [pageSizeOptions]="[5, 10]"></mat-paginator>

      <div class="showStatus">
        <div>Inspected : {{data[crntYear].Towers.filter(inspectedList).length}} Towers</div>
        <div>Uninspected : {{data[crntYear].Towers.filter(unInspectedList).length}} Towers</div>
       </div>
    </div>
    
  </div>
  </div>