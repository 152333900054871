
<div class="navBar">
  <app-navbar [main]="false"></app-navbar>
</div>

<div class="summary">
  <div class="summaryTabs one">
    <h3 class="summaryHeading">Inspection Summary </h3>
    <div class="summaryContent">
      <div class="contentBox">
<app-categories [year]="year" [towerName]="towerName" *ngIf="isLoaded" [data]="data"></app-categories>
      </div>
      <div class="contentBox">
<app-inspection-details *ngIf="isLoaded" [data]="data.General"></app-inspection-details>
      </div>
      <!-- <div class="contentBox">
        <app-tenants *ngIf="isLoaded" [data] = "data"></app-tenants>
      </div> -->
     <div class="contentBox">
         <app-site-verification [summary]="summary" *ngIf="isLoaded" (editEvent)="editEvent($event)"></app-site-verification>
      </div>
      <div class="contentBox">
        <app-site-approval [summary]="summary" [role]="role" *ngIf="isLoaded" (editEvent)="editEvent($event)"></app-site-approval>
      </div>
    </div>
  </div>
  <div class="summaryTabs two">
 <img class="image" *ngIf="isLoaded" [src]="data.General[1].Details[0].Image">
  </div>
</div>
