import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DigitaltwinFetchService } from '../digitaltwin-fetch.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dialoguploadcomponent',
  templateUrl: './dialoguploadcomponent.component.html',
  styleUrls: ['./dialoguploadcomponent.component.css']
})
export class DialoguploadcomponentComponent implements OnInit {
    scenePercentage: Observable<number>
    scene_lowPercentage: Observable<number>
    sceneURL: Observable<string>
    scene_lowURL: Observable<string>
  scene_low
  scene
  isUploading
  towerName
  progressDisplay = {
    scene : 0,
    scene_low : 0
  }
  constructor(private snackBar: MatSnackBar,public dialog: MatDialog,private fireStore : AngularFirestore,private storage : AngularFireStorage,@Inject(MAT_DIALOG_DATA) public data: {towerName: string},private service: DigitaltwinFetchService) {
    this.towerName = this.data.towerName
   }

  ngOnInit(): void {
    this.isUploading = false
  }


  inputFileChange(event, className){
    console.log(event, className);
    let _fileName = event.target.files[0].name

    if(_fileName.split('.').includes('glb')){
      let _span = document.querySelector(`.${className}`)
      _span.textContent = _fileName
      className.split('-').includes('low') ? this.scene_low = event.target.files[0] : this.scene = event.target.files[0];

    }else{      
      this.snackBar.open('Upload GLB File', "close", { duration: 3000 });
    }
    
  }

  closeDialog(){
    this.dialog.closeAll()
  }
  upload(){
    
    if(this.scene || this.scene_low){
      this.service.fetchData(this.towerName).subscribe((result) => {
         let towerData = result.data()
         this.isUploading = true;
      //High
      if(this.scene){
        let _sceneRef = this.storage.ref(`DigitalTwin/${this.towerName}/scene.glb`)
        let _scene = this.storage.upload(`DigitalTwin/${this.towerName}/scene.glb`,this.scene);
        _scene.snapshotChanges().pipe(
          finalize(async () => {
            let url = await _sceneRef.getDownloadURL().toPromise()
            towerData["DigitalTwin"]["CAD"]["Link"] = url
            this.service.setData(this.towerName).update(towerData).then(() => {
            }).catch(err => {
              console.log("High scene link upload error ",err)
            })
          })
          )
        .subscribe()
        this.scenePercentage = _scene.percentageChanges()
        this.scenePercentage.subscribe((data) =>{
          this.progressDisplay.scene = Math.floor(data)
          if(this.scene &&  this.scene_low){
            if(this.progressDisplay.scene == 100 && this.progressDisplay.scene_low == 100){
              this.dialog.closeAll()
              this.snackBar.open('Scene Uploaded', "close", { duration: 3000 });    
            }
          }else{
            if(this.progressDisplay.scene == 100){
              this.dialog.closeAll()
              this.snackBar.open('Scene Uploaded', "close", { duration: 3000 });    
            }
          }
          
        })
      }


        //Low
        if(this.scene_low){
          let _scene_lowRef = this.storage.ref(`DigitalTwin/${this.towerName}/scene_low.glb`)
          let _scene_low = this.storage.upload(`DigitalTwin/${this.towerName}/scene_low.glb`,this.scene_low);
          _scene_low.snapshotChanges().pipe(
            finalize(async () => 
            {
              let url = await _scene_lowRef.getDownloadURL().toPromise()
              towerData["DigitalTwin"]["CAD"]["Link_low"] = url
              this.service.setData(this.towerName).update(towerData).then(() => {
              }).catch(err => {
                console.log("Low scene link upload error ",err)
              })
            })
           )
          .subscribe()
          this.scene_lowPercentage = _scene_low.percentageChanges()
          this.scene_lowPercentage.subscribe((data) =>{
            this.progressDisplay.scene_low = Math.floor(data)
            if(this.scene &&  this.scene_low){
              if(this.progressDisplay.scene == 100 && this.progressDisplay.scene_low == 100){
                this.dialog.closeAll()
                this.snackBar.open('Scene Uploaded', "close", { duration: 3000 });    
              }
            }else{
              if(this.progressDisplay.scene_low == 100){
                this.dialog.closeAll()
                this.snackBar.open('Scene Uploaded', "close", { duration: 3000 });    
              }
            }
          })
        }
      })

    }else{
      this.snackBar.open('Upload GLB File', "close", { duration: 3000 });
    }

  }

}
