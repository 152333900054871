import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from 'src/environments/environment';
declare let CryptoJS:any

@Injectable({
  providedIn: 'root'
})
export class SpinModelServiceService {

  direcArray = []
  allKeys = []
  linkObj = {}

  encryptedString = localStorage.getItem('creds')
  decryptedStream = CryptoJS.AES.decrypt(this.encryptedString, environment.secret)
  credentials:any = JSON.parse(this.decryptedStream.toString(CryptoJS.enc.Utf8))
  s3 = new S3(this.credentials.accessData.spinModelS3);

  data: any;
  constructor(private fireStore : AngularFirestore) {
   }

  fetchData(towerName){
    return this.fireStore.collection(towerName).doc(towerName).get();
}

setData(tower){
  return  this.fireStore.collection(tower).doc(tower);
  }

  async getS3() {
    this.allKeys = []
    // console.log(this.s3);
    const params = {
      Bucket: "aerodyne-telstra-dev",
      Prefix: 'Temp/final_telstra_processed_files/metadata-processed/telstra',
      StartAfter: 'Temp/final_telstra_processed_files/metadata-processed/telstra'
    }
    
    const listAllKeys = (params, out = []) => new Promise((resolve, reject) => {
      this.s3.listObjectsV2(params).promise()
        .then(({Contents, IsTruncated, NextContinuationToken}) => {
          out.push(...Contents);
          !IsTruncated ? resolve(out) : resolve(listAllKeys(Object.assign(params, {ContinuationToken: NextContinuationToken}), out));
        })
        .catch(reject);
    });
    
    this.data = await listAllKeys(params)
      
    this.data.forEach(obj => this.allKeys.push(obj.Key));
    return this.getDirectoriesName(this.allKeys)
  }

  getDirectoriesName(allKeys){
    this.linkObj= {}
    this.direcArray = []
    console.log("all keys: ",allKeys)
      for(var item of allKeys){
        if(item.includes("/")){
          let direc = item.split("/")[4]
          let fulldirecPath = item
          if(!this.direcArray.includes(direc)){
            this.direcArray.push(direc)
            this.linkObj[direc] = [fulldirecPath]
          }
          else{
            this.linkObj[direc] = [...this.linkObj[direc],fulldirecPath]
          }
        }
      }
    return { 'keyNames' : this.direcArray, "fullPath" : this.linkObj }
  }

  isImgUrl(url) {
    const img = new Image();
    img.src = url;
    return new Promise((resolve) => {
      img.onerror = () => resolve(false);
      img.onload = () => resolve(true);
    });
    
  }

}






