import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private fireStore : AngularFirestore) { }

  fetchData(towerName){
    return this.fireStore.collection(towerName).doc(towerName).get();
}



}
