<div class="navBar">
  <app-navbar [main]="false"></app-navbar>
</div>

<div class="digitaltwin">
  <div class="digitaltwinTab">
    <ng-container *ngIf="Link == null || Link == ''">
      <div *ngIf="glbLoaderPrg == 'dn' && Isdigi" style="float: right; float: right; z-index: 5; position: absolute; right: 15px; top: 15px;">
        <div style="height: 120px;width: 400px;border: limegreen solid 2px; border-radius: 10px;background-color: rgb(68, 69, 70);">
          <div style="display:flex; justify-content: space-between;">
            <div class="compass-container">
              <div class="compass"> 
                <div class="arrow" #compass_arrow></div>
                <div class="disc" id="compassDiscImg"></div>
              </div>
            </div>
            <div class="control-btn">
              <div class="toggle_radio">
                <input type="radio" class="toggle_option" id="first_toggle" name="toggle_option" checked (change)="onItemChange($event.target.value)" value="Rotate">
                <input type="radio" class="toggle_option" id="second_toggle" name="toggle_option"(change)="onItemChange($event.target.value)" value="Pan">
                <label for="first_toggle">Rotate</label>
                <label for="second_toggle">Pan</label>
                <div class="toggle_option_slider"></div>
              </div>
              <div class="btns">
                <button (click)="zoomOutMouseDwn($event)" class="btn1">-</button>
                <button (click)="zoomInMouseDwn($event)" class="btn2">+</button>
              </div>
            </div>
            <div class="arrows" style="padding-top: 16px;margin-right: -19px;margin-left: 10px;">
              <div class="arrow-up" (click)="upBtnClick($event)" ></div>
              <div class="arrow-down" (click)="downBtnClick($event)"></div>
              <div class="arrow-left" (click)="leftBtnClick($event)" ></div>
              <div class="arrow-right" (click)="rightBtnClick($event)"></div>
              <!-- <div class="select-box" (click)="recenterBtnClick($event)"></div> -->
              <div class="select-box" (click)="recenterBtnClick()"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="canvasContainer" #container (click)="clickEvent($event)" >
        <div style="position:relative;" [ngClass]="glbLoaderPrg">
          <p style="color: white;z-index: 6;position:absolute;right:48%;top:36vh">{{ percentageLoaded.prtLd }} %</p>
          <mat-progress-bar mode="determinate" style="z-index: 5;height:20px;border-radius:5px;position:absolute;top:34vh;left:150px;width:82vw;background-color: #274545;" [value]="percentageLoaded.prtLd"></mat-progress-bar>
        </div>
        <div style="position:relative;" [ngClass]="glbLoaderPrgHigh">
          <div style="position:absolute;top:75vh;width:100%;display:flex;flex-direction: column;justify-content: center;align-items: center;z-index: 6;">
            <p style="color: white;z-index: 6;font-size: 16px">Asset details available in high resolution model</p>
            <mat-progress-bar mode="determinate" style="z-index: 5;height:10px;border-radius:5px;width:50vw;background-color: #274545;" [value]="percentageLoaded.prtLdHigh"></mat-progress-bar>
            <p style="color: white;z-index: 6;font-size: 16px;margin-top:10px">Loading high resolution version   {{ percentageLoaded.prtLdHigh }} %</p>
          </div>
        </div>
      </div>
      <div *ngIf="!Isdigi" style="position: absolute;width:100%;text-align:center;top: 34vh;left: 0vw;font-size: 35px;color: #000000">
        <p>{{IsDigiError}}</p></div>

      <!--Features-->
      <mat-button-toggle-group  [(value)]="selectedFeature" class="features mat-elevation-z8" name="features">
        <mat-button-toggle  [value]="1" *ngIf="glbLoaderPrg == 'dn' && Isdigi && isAmplit" (change)="onValChange($event.value)" >
          <mat-icon class="featuresIcon" [ngClass]="{'selectedFeatureIcon' : selectedFeature == 1}">map</mat-icon>
          <p class="featuresText" [ngClass]="{'selectedFeature' : selectedFeature == 1}">Site Details<br></p>
        </mat-button-toggle>
        <mat-button-toggle [value]="2" *ngIf="glbLoaderPrg == 'dn' && Isdigi && isAmplit" (change)="onValChange($event.value)">
          <mat-icon class="featuresIcon" [ngClass]="{'selectedFeatureIcon' : selectedFeature == 2}">change_history</mat-icon>
          <p class="featuresText" [ngClass]="{'selectedFeature' : selectedFeature == 2}">Structure</p>
        </mat-button-toggle>
        <mat-button-toggle [value]="3" *ngIf="glbLoaderPrg == 'dn' && Isdigi && isAmplit" (change)="onValChange($event.value)">
          <mat-icon class="featuresIcon" [ngClass]="{'selectedFeatureIcon' : selectedFeature == 3}">settings_input_antenna</mat-icon>
          <p class="featuresText" [ngClass]="{'selectedFeature' : selectedFeature == 3}">Antenna</p>
        </mat-button-toggle>
        <mat-button-toggle [value]="4" *ngIf="glbLoaderPrg == 'dn' && Isdigi && isAmplit" (change)="onValChange($event.value)">
          <mat-icon class="featuresIcon" [ngClass]="{'selectedFeatureIcon' : selectedFeature == 4}">device_hub</mat-icon>
          <p class="featuresText" [ngClass]="{'selectedFeature' : selectedFeature == 4}">Head Frame - Mount</p>
        </mat-button-toggle>
        <mat-button-toggle [value]="5" *ngIf="glbLoaderPrg == 'dn' && Isdigi && isAmplit" (change)="onValChange($event.value)">
          <mat-icon class="featuresIcon" [ngClass]="{'selectedFeatureIcon' : selectedFeature == 5}"> cell_wifi</mat-icon>
          <p class="featuresText" [ngClass]="{'selectedFeature' : selectedFeature == 5}">Transceiver - Junction Device</p>
        </mat-button-toggle>
        <mat-button-toggle [value]="6" *ngIf="glbLoaderPrg == 'dn' && Isdigi && isAmplit" (change)="onValChange($event.value)">
          <mat-icon class="featuresIcon" [ngClass]="{'selectedFeatureIcon' : selectedFeature == 6}">brightness_auto</mat-icon>
          <p class="featuresText" [ngClass]="{'selectedFeature' : selectedFeature == 6}">Aviation Light</p>
        </mat-button-toggle>
        <mat-button-toggle [value]="7" *ngIf="glbLoaderPrg == 'dn' && Isdigi && isAmplit" (change)="onValChange($event.value)">
          <mat-icon class="featuresIcon" [ngClass]="{'selectedFeatureIcon' : selectedFeature == 7}"> table_chart</mat-icon>
          <p class="featuresText" [ngClass]="{'selectedFeature' : selectedFeature == 7}">Shelter</p>
        </mat-button-toggle>
        <!-- <mat-button-toggle *ngIf="glbLoaderPrg == 'dn' && Isdigi" (click)="uploadClick()">
          <mat-icon class="featuresIcon">cloud_upload</mat-icon>
          <p class="featuresText">Upload</p>
        </mat-button-toggle> -->
        <mat-button-toggle [value]="8" (change)="onValChange($event.value)">
          <mat-icon class="featuresIcon" [ngClass]="{'selectedFeatureIcon' : selectedFeature == 8}">3d_rotation
          </mat-icon>
          <p class="featuresText" [ngClass]="{'selectedFeature' : selectedFeature == 8}">Reload</p>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <!--SiteGen-->
      <mat-card class="siteData equip mat-elevation-z8" *ngIf="selectedFeature <= 2 && siteData && glbLoaderPrg == 'dn' && Isdigi">
        <mat-card-header class="siteDataHeader">
          <mat-card-title>
           {{amplitelDataCardHeader}}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="siteDataContent">
          <table class="siteDataTable">
            <tr class="sRow" *ngFor="let item of amplitelDataCard.Details">
              <td>{{item.Name}}</td>
              <td>{{item.Value}}</td>
            </tr>
          </table>
        </mat-card-content>
      </mat-card>
     
      <!--Equipments List-->
      <mat-card class="siteData equip mat-elevation-z8" style="min-width: 300px;" *ngIf="selectedFeature >= 3 && selectedFeature <= 8 && glbLoaderPrg == 'dn' && Isdigi">
        <mat-card-header class="equipmentListBoxHeader">
          <mat-card-title>
            {{amplitelDataCardHeader}}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="equipmentListBoxContent">
          <mat-button-toggle-group *ngIf="auditData" [(value)]="selectedEquipment" class="shelter mat-elevation-z8"
            name="shelter">
            <mat-button-toggle *ngFor="let item of amplitelDataCardEq;let i = index;"
              (click)="toggleEquipments(item.Name,i)" [value]="i" class="eRow">
              <div class="td1" [ngClass]="{'selectedFeature' : selectedEquipment == i}">{{i+1}}</div>
              <div class="td2" [ngClass]="{'selectedFeature' : selectedEquipment == i}">{{item.Name}}</div>
              <div class="td3 equipmentsIcon" [ngClass]="{'selectedFeature' : selectedEquipment == i}"
                (click)="toggleAsset($event,item.Name)">
                <mat-icon>visibility</mat-icon>
                <mat-icon style="display: none;">visibility_off</mat-icon>
              </div>
              <div class="td4 equipmentsIcon">
                <mat-icon style="color:grey;" (click)="toggleWaves($event,item.Name)">sensors</mat-icon>
                <!-- <mat-icon style="color:grey;" *ngIf="item.Name.startsWith('RRU') == 1;">sensors_off</mat-icon> -->
              </div>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </mat-card-content>
      </mat-card>

      <!--Equipment Box-->
      <mat-card class="equipment equip right mat-elevation-z8"
        *ngIf="selectedFeature >= 3 && selectedEquipment != null && isOpen === true && glbLoaderPrg == 'dn' && Isdigi">
        <mat-card-header class="equipmentHeader">
          <mat-card-title>
            {{assetData.Name}}
          </mat-card-title>
          <div>
            <mat-icon *ngIf="role === 'aero-processing' || role === 'telstra-processing' || role === 'super-admin'" class="editIcon"
                    (click)="editData('Image',assetData.Image,'AmplitelData')"> edit</mat-icon>
          <i (click)="closeBox()">
            <mat-icon class="closeIcon">close</mat-icon>
          </i>
          </div>
        </mat-card-header>
        <mat-card-content class="equipmentContent">
          <div class="imgDiv" *ngIf="assetData.Image.length > 0">
            <span class="arrowLeft" *ngIf="assetData.Image.length > 1" (click)="prevImg()">
              <button class="iconBtn" mat-mini-fab>
                <mat-icon class="iconLeft">keyboard_arrow_left</mat-icon>
              </button>
            </span>
            <span class="arrowRight" *ngIf="assetData.Image.length > 1" (click)="nextImg()">
              <button class="iconBtn" mat-mini-fab>
                <mat-icon class="iconRight">keyboard_arrow_right</mat-icon>
              </button>
            </span>
            <img class="img-fluid" [src]="assetData.Image[crntImg]">
          </div>
          <div class="detailsDiv">
            <table mat-table [dataSource]="assetData.Details" class="equipmentTable mat-elevation-z8">

              <!-- Name Column -->
              <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element" width="50%">
                  <div><span class="padd">{{element.Name}}</span>
                  </div>
                </td>
              </ng-container>

              <!-- Value Column -->
              <ng-container matColumnDef="Value">
                <th mat-header-cell *matHeaderCellDef> Value </th>
                <td mat-cell *matCellDef="let element" width="50%">
                  <p class="roundedBoxD"
                    [ngClass]="{'monitor' : element.Option == 1,'planned' : element.Option == 2,'urgent' : element.Option == 3,'emergency' : element.Option == 4}">
                    {{element.Value}}
                  </p>
                </td>
              </ng-container>

              <tr mat-header-row
                *matHeaderRowDef="(role === 'aero-processing' || role === 'telstra-processing' || role === 'super-admin')  ? displayedColumns: displayedColumnsUser">
              </tr>
              <tr mat-row
                *matRowDef="let row; columns: (role === 'aero-processing' || role === 'telstra-processing' || role === 'super-admin')  ? displayedColumns: displayedColumnsUser">
              </tr>
            </table>
          </div>
        </mat-card-content>
      </mat-card>

      <!--Measure-->
      <mat-card class="editMode equip mat-elevation-z8" *ngIf="selectedFeature == 9">
        <mat-card-header class="siteDataHeader">
          <mat-card-title>
            Measure
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="siteDataContent">
          <div class="editModediv" *ngIf="editMode == 'Add'">
            <button mat-raised-button color="primary" (click)="startMeasurement()">
              <mat-icon>code</mat-icon>Start
            </button>
            <button mat-raised-button color="primary" (click)="resetMeasurement()">
              <mat-icon>cached</mat-icon>Reset
            </button>
          </div>

          <p style="width: 100; text-align: center;">Measured Value: {{measurementVal}}</p>
          <!-- <p></p> -->
        </mat-card-content>
      </mat-card>
    </ng-container>

      <ng-container *ngIf="Link != null && Link != ''">
        <iframe style="width: 100%;height: 100%;" [src]="urlSafe"></iframe>
      </ng-container>
    </div>
</div>

