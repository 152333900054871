import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DigitaltwinFetchService {
url;
  constructor(private fireStore : AngularFirestore,private http :  HttpClient,public sanitizer: DomSanitizer,private storage : AngularFireStorage) { }


  fetchData(tower){
    return this.fireStore.collection(tower).doc(tower).get();
  }


  setData(tower){
    return  this.fireStore.collection(tower).doc(tower);
  }

  towersData(year){
    return this.fireStore.collection('towers').doc(year);
  }

  loadGLTF(link){
    return this.http.get(link);
  }

  async fetchAmpList(){
    try {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      })
      console.log("HEADERS", headers);
      
      const response: any = await this.http.get(`http://localhost:9000/process`, { headers: headers,responseType: 'json', observe: 'response' }).toPromise()
      return response
      }
      catch(e){
        console.log(e)
        return e
      }
  }

  async importAmpitelUpload(live_site_name,display_name){
    const response= await this.http.post("http://localhost:9000/process",{live_site_name,display_name}).toPromise();
    return response
  }

  async exportSiteToAMS(towerName,year){
    const response= await this.http.post("http://localhost:9000/export",{towerName,year}).toPromise();
    return response
  }

}
