import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.css']
})
export class FilesComponent implements OnInit {
@Input() data;
@Input() role;
deleteIndex = [];
mode = null;
@Output() updateLink = new EventEmitter<any>();

@Output() editDoc = new EventEmitter<any>();
file = {};
  constructor() { }

  ngOnInit(): void {
  }


  sendLink(link){
this.updateLink.emit(link);
  }

//triggering the add doc function from child component to parent component
  addDoc(event){
    this.file = event.files;
    this.editDoc.emit({
      file: this.file,
      action : 'Add'
    });
    this.file = [];
  }

//triggering the edit doc function from child component to parent component
  editName(name,index){
    this.editDoc.emit({
      name,
      index,
      action : 'Edit'
    });
  }

  deleteItems(event,name){
    if(event.target.checked){
      this.deleteIndex.push(name);
    }else{
    this.deleteIndex = this.deleteIndex.filter(elt =>{
     return elt != name;
    });
    event.target.checked = false;
    }
  }


//triggering the delete doc function from child component to parent component
  deleteDoc(){
    if(this.mode == 'Delete'){
      if(this.deleteIndex.length > 0){
         this.editDoc.emit({
         files: this.deleteIndex,
         action : 'Delete'
       });
      }
      this.deleteIndex = [];
    this.mode = null;
  }else{
    this.mode = 'Delete';
  }
  }


}
