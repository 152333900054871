import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
  value: string;
  name: string;
}
@Component({
  selector: 'app-add-section',
  templateUrl: './add-section.component.html',
  styleUrls: ['./add-section.component.css']
})
export class AddSectionComponent implements OnInit {
  section = 'Equipment';
  leg = 'Leg 1';
  equipment = "RF";
  equipmentName = null;
  sectionName = null;
  result = {};

  mulResults = [

  ]

  mode = 'Normal';
  rf = ['Equipment Reference',
    'Equipment Leg',
    'Carrier',
    'Equipment Type',
    'Dimension H(m)xW(m)xD(m)',
    'Height from ground (m)',
    'Azimuth °',
    'Equipment damage visible?',
    'Feeder damage visible?'
  ];
  rru = [
    'Equipment Reference',
    'Equipment Leg',
    'Equipment Location',
    'Dimension H(m)xW(m)xD(m)',
    'Height from ground Equipment (m)'
  ];
  mw = [
    'Equipment Reference',
    'Equipment Leg',
    'Carrier',
    'Equipment Type',
    'Diameter (m)',
    'Height from ground (m)',
    'Azimuth °',
    'Equipment damage visible?',
    'Feeder damage visible?'
  ];

  rfDetails = [];
  rruDetails = [];
  mwDetails = [];

  cv = [
    'Class',
    'Type',
    'Antenna No',
    'Structure No',
    'Polarity',
    'Height (m)',
    'Owner',
    'Status',
    'CANRAD Correct'
  ]

  cvDetails = [];

  cr = [
    'Description of Corroded Component',
    'Quantity',
    'Severity',
    'Percentage of Surface Area Affected by Corrosion',
    'Additional Comments'
  ]

  crDetails = [];



  constructor(public dialogRef: MatDialogRef<AddSectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    for (let i = 0; i < this.rf.length; i++) {
      this.rfDetails.push({
        "Name": this.rf[i],
        "Value": "",
        "Option": "",
        "Image": []
      })
    }

    for (let i = 0; i < this.mw.length; i++) {
      this.mwDetails.push({
        "Name": this.mw[i],
        "Value": "",
        "Option": "",
        "Image": []
      })
    }


    for (let i = 0; i < this.rru.length; i++) {
      this.rruDetails.push({
        "Name": this.rru[i],
        "Value": "",
        "Option": "",
        "Image": []
      })
    }

    for (let i = 0; i < this.cv.length; i++) {
      this.cvDetails.push({
        "Name": this.cv[i],
        "Value": "",
        "Option": "",
        "Image": []
      })
    }


    for (let i = 0; i < this.cr.length; i++) {
      this.crDetails.push({
        "Name": this.cr[i],
        "Value": "",
        "Option": "",
        "Image": []
      })
    }



  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  resultFn() {
    this.mulResults = []
    if (this.section == "Equipment") {
      this.result["Name"] = this.equipmentName;
      this.result["Leg"] = this.leg;
      if (this.equipment == 'RF') {
        this.result["Details"] = this.rfDetails;
      }
      if (this.equipment == 'RRU') {
        this.result["Details"] = this.rruDetails;
      }
      if (this.equipment == 'MW') {
        this.result["Details"] = this.mwDetails;
      }
      this.result["Details"][1].Value = this.leg;
      this.result["Details"][1].Option = 1;
      this.result["Details"][0].Value = this.equipmentName;
      this.result["Details"][0].Option = 1;
      return { data: this.result, section: this.section};
    }
    if (this.section == "General") {

      if (this.mode == "Normal") {
        this.result["Name"] = this.sectionName;
        this.result["Details"] = [];
        return { data: this.result, section: this.section};
      }
      if (this.mode == "CanradVariations") {
            this.result["Name"] = this.sectionName;
            this.result["Details"] = this.cvDetails;
            return { data: this.result, section: this.section};
      }
      if (this.mode == "CorrosionReport") {
        this.result["Name"] = this.sectionName;
        this.result["Details"] = this.crDetails;
        return { data: this.result, section: this.section};
      }
    }
  }

}
