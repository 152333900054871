import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MapFetchService } from './map-fetch.service';
import { Router} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MapComponent implements OnInit {
  @ViewChild("map") map;
  crntYear = 0;
  markers = [];
  year = ['2021','2022','2023']
  zoom = 5;
  data = [];
  crntIssue = 'All';
  towerName;
  zoomControl = false;
  scrollwheel = true;
  disableDoubleClickZoom = true;
  maxZoom = 15;
  minZoom = 1;
  disableDefaultUI = true;
  center = { lat: -24, lng: 134 }
  labelShow = true;
  role;
  processedStatus = {
    OrthoMap : {
      towers: 0,
      percent: 0
    },
    DigitalTwin : {
      towers: 0,
      percent: 0
    },
    TowerView : {
      towers: 0,
      percent: 0
    }
  }
  processedStatusArr = ['OrthoMap','DigitalTwin','TowerView']
  constructor(private service: MapFetchService, private router: Router,private snackBar : MatSnackBar) { }

ngOnChange(){
}

  ngOnInit(): void {
    if (this.router.url.search("aero-processing") == 1) {
      this.role = "aero-processing";
    }
    if(this.router.url.search("user") == 1){
      this.role = 'user';
    }
    if(this.router.url.search("pilot") == 1){
      this.role = 'pilot';
    }
    if(this.router.url.search("super-admin") == 1){
      this.role = 'super-admin';
    }
    if(this.router.url.search("telstra-processing") == 1){
      this.role = 'telstra-processing';
    }
    this.fetchTowers(this.year[this.crntYear])
  }

  fetchTowers(year){
    this.service.fetchData('TOWERS' + "/" + year + "/" + year).forEach(data => {
      let towerList = []
      data.forEach(doc => {
        let data: any = doc.data()
        towerList = [...towerList,data] 
      })
      this.data[this.crntYear] = {Towers:towerList, Year: year};
    }).then(() => {
      this.processedStatusArr.forEach((col) => {
          this.getTowers(col)
      })
    })
  }

  
  yearChange(){
    this.fetchTowers(this.year[this.crntYear])
  }
  changeRoute(Tower,status, markerNw) {
    console.log('markerNw= ', markerNw);
    
    this.towerName = Tower.replaceAll(" ",'');
    if(status == 'Inspected' && this.role != 'user'){
      this.router.navigate(['/'+this.role+'/'+ this.data[this.crntYear].Year  +'/' +this.towerName+ '/summary']);
    }
    if(status == 'Inspected' && markerNw.Completed == 'No' && this.role == 'user'){
      this.snackBar.open('This Site is not ready yet!', "close", { duration: 2000 });
    }
    if(status == 'Inspected' && markerNw.Completed == 'Yes' && this.role == 'user'){
      this.router.navigate(['/'+this.role+'/'+ this.data[this.crntYear].Year  +'/' +this.towerName+ '/summary']);
    }
    if(status === 'Uninspected' && this.role === 'pilot'){
      this.router.navigate(['/'+this.role+'/'+ this.data[this.crntYear].Year  +'/' +this.towerName+ '/upload']);
    }
    else if(status === 'Uninspected'){
      this.snackBar.open('This Site is not ready yet!', "close", { duration: 2000 });
    }
  }


  zoomIn() {
    if (this.zoom < this.maxZoom) this.zoom++;
  }

  zoomOut() {
    if (this.zoom > this.minZoom) this.zoom--;
  }


  // removeLabel(){
  //   if(this.labelShow){
  //     this.labelShow = false;
  //     this.map._elem["nativeElement"].querySelector(".gm-style-iw-a").style.display = "none";
  //   }else{
  //     this.labelShow = true;
  //     this.map._elem["nativeElement"].querySelector(".gm-style-iw-a").style.display = "block";
  //   }
  // }

  all(record){
    return true;
  }

  uninspected(record){
    return record.Status == "Uninspected";
  }
  inspected(record){
    return record.Status == "Inspected";
  }
  completed(record){
    return record.Completed == "Yes";
  }
  emergency(record){
    return record.Status == "Emergency";
  }
  urgent(record){
    return record.Status == "Urgent";
  }
  planned(record){
    return record.Status == "Planned";
  }
  monitor(record){
    return record.Status == "Monitor";
  }



  pole(record){
    return record.StructureType == "Pole";
  }

  mast(record){
    return record.StructureType == "Mast";
  }

  tower(record){
    return record.StructureType == "Tower";
  }


  heavy(record){
    return record.Corrosion == "Heavy";
  }

  medium(record){
    return record.Corrosion == "Medium";
  }

  light(record){
    return record.Corrosion == "Light";
  }

  surface(record){
    return record.Corrosion == "Surface";
  }

  cvNo(record){
    return record.CanradVariation == "No";
  }

  cvYes(record){
    return record.CanradVariation == "Yes";
  }


  onMouseOver(infoWindow, $event: MouseEvent) {
    infoWindow.open();
}

onMouseOut(infoWindow, $event: MouseEvent) {
    infoWindow.close();
}

clickEvent(event){
  event.preventDefault();
}

getTowers(column){
  // console.log('this.crntYear - ', this.crntYear);
  let filteredList = []
  if(column == 'OrthoMap'){
    filteredList = this.data[this.crntYear]['Towers'].filter((obj) => {
      if(obj.hasOwnProperty('processed')){
        return obj.processed.indexOf('O') !== -1
      }
      return false
    })
    this.processedStatus[column].towers = filteredList.length
    this.processedStatus[column].percent = ((filteredList.length / this.data[this.crntYear]['Towers'].length) * 100).toFixed(2)
  }
  else if(column == 'DigitalTwin'){
    // console.log('this.crntYear - ', this.crntYear);
    
    // if (this.crntYear == 0){
    //   this.processedStatus[column].towers = 1184
    //   // this.processedStatus[column].towers = 1165
    //   // this.processedStatus[column].percent = ((1122 / this.data[this.crntYear]['Towers'].length) * 100).toFixed(2)
    // }
    // else if (this.crntYear == 1){
    //   this.processedStatus[column].towers = 324
    //   // this.processedStatus[column].towers = 297
    //   // this.processedStatus[column].towers = filteredList.length
    //   // this.processedStatus[column].percent = ((240 / this.data[this.crntYear]['Towers'].length) * 100).toFixed(2)
    // }
    // else if (this.crntYear == 2){
    //   this.processedStatus[column].towers = 0
    // }

    filteredList = this.data[this.crntYear]['Towers'].filter((obj) => {
      if(obj.hasOwnProperty('processed')){
        return obj.processed.indexOf('D') !== -1
      }
      return false
    })
    this.processedStatus[column].towers = filteredList.length
    this.processedStatus[column].percent = ((filteredList.length / this.data[this.crntYear]['Towers'].length) * 100).toFixed(2)
  }
  else if(column == 'TowerView'){
    filteredList = this.data[this.crntYear]['Towers'].filter((obj) => {
      if(obj.hasOwnProperty('processed'))
      {
        return obj.processed.indexOf('T') !== -1
      }
      return false
    })
    this.processedStatus[column].towers = filteredList.length
    this.processedStatus[column].percent = ((filteredList.length / this.data[this.crntYear]['Towers'].length) * 100).toFixed(2)
  }
}

}
