import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpinModelServiceService } from "./spin-model-service.service";
import { EditComponent } from './../edit/edit.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogUploadComponent } from './dialog-upload/dialog-upload.component';

@Component({
  selector: 'app-spin-view',
  templateUrl: './spin-view.component.html',
  styleUrls: ['./spin-view.component.css'],
  providers: []
})
export class SpinViewComponent implements OnInit {
  @ViewChild("content") content;
  tower : string;
  role;
  view : string;
  imageData = [];
  prevDir;
  crntDir;
  index;
  sliderIndex;
  loaded;
  result;
  data;
  dirValue;
  loading;
  towerName;
  year;
  constructor(private dialog: MatDialog,private route: ActivatedRoute,private service : SpinModelServiceService, private renderer : Renderer2, private snackBar: MatSnackBar, private router: Router) {
    this.loading = false;
    this.crntDir = 0;
    this.index = 0;
    this.prevDir = 0;
    this.sliderIndex = false;
    this.loaded = false;
  if (this.router.url.search("aero-processing") == 1) {
    this.role = "aero-processing";
  }
  if (this.router.url.search("user") == 1) {
    this.role = 'user';
  }
  if (this.router.url.search("pilot") == 1) {
    this.role = 'pilot';
  }
  if (this.router.url.search("super-admin") == 1) {
    this.role = 'super-admin';
  }
  if (this.router.url.search("telstra-processing") == 1) {
    this.role = 'telstra-processing';
  }
   this.getData()
    this.route.params.subscribe(params =>{
      this.tower = params.tower;
      this.view = params.view;
      this.loading = false;
      this.crntDir = 0;
      this.index = 0;
      this.prevDir = 0;
      this.sliderIndex = false;
      this.loaded = false;
      this.loadtowerview()
    });

  }

  ngOnInit(): void {

  }

  ngAfterViewChecked() {

  }

  loadtowerview(){
    this.service.fetchData(this.tower).subscribe(
      (result)=>{
        let temp = {};
              temp = result.data();
      if(this.view === "towerview"){
        this.view = 'TowerView';
         }
         if(this.view === "topdown"){
          this.view = 'TopDown';
         }
         if(this.view === "los"){
          this.view = 'Los';
         }
         this.imageData = temp[this.view];
         if(this.tower.includes("-KORD") || this.tower.includes("_KORD"))
           {
             for(let i = 0;i < this.imageData.length;i++){
              this.imageData[i].Payload =  this.imageData[i].Payload.map((data) => {
                let dataArr = data.file.split("/")
                dataArr[4] = "Towers_Kordia"
                let dataLink = dataArr.join("/")
                data.file = dataLink
                return data
              })
             }
           }
           else if(this.tower.includes("-BAI") || this.tower.includes("_BAI"))
           {
             for(let i = 0;i < this.imageData.length;i++){
              this.imageData[i].Payload =  this.imageData[i].Payload.map((data) => {
                let dataArr = data.file.split("/")
                dataArr[4] = "Towers_BAI"
                let dataLink = dataArr.join("/")
                data.file = dataLink
                return data
              })
             }
           }
           else if(this.tower.includes("-FY23") || this.tower.includes("_FY23"))
           {
             for(let i = 0;i < this.imageData.length;i++){
              this.imageData[i].Payload =  this.imageData[i].Payload.map((data) => {
                let dataArr = data.file.split("/")
                let tempArr = [...dataArr]
                if(dataArr[dataArr.length - 3] != "processed_images"){
                  if(dataArr[dataArr.length - 4] == "new23"){
                    dataArr = dataArr.filter(d=> !(d=="new23"))
                    let ind = dataArr.indexOf("SpinProcessed")
                    dataArr[ind] = "FY23_processed"  
                  }else{
                    dataArr = dataArr.filter(d=> !(d=="final_telstra_processed_files"))
                    let ind = dataArr.indexOf("SpinProcessed")
                    dataArr[ind] = "Towers_FY23"
                  }
                  console.log(dataArr)
                }
                else if(dataArr[7].includes("processed_images")){
                  dataArr[4] = "Aerodyne_Telstra_Data_Store"
                }
                else
                {
                  dataArr[4] = "final_telstra_processed_files"
                }
                let dataLink = dataArr.join("/")
                data.file = dataLink
                return data
              })
             }
           }
      },
      (err)=>{
        this.snackBar.open(err, "close", { duration: 2000 });
      },
      ()=>{
      }
    )
  }

  //for choosing the dir inside the view
  setCrntDir(event){
    this.crntDir = event.crntDir;
    this.index = event.index;
  }
    sendIndex(event){
      this.sliderIndex = event.sliderIndex;
      this.index = event.index;
    }

    //edit the directory name listed under views
    editDir(event){
        const dialogRef = this.dialog.open(EditComponent, {
          width: '450px',
          maxHeight: '480px',
          data: { name:"Directory", value: event.value }
        });
        dialogRef.afterClosed().subscribe(
          (result) => {
            this.dirValue = result.value;
          },
          err => {
            this.snackBar.open(err, "close", { duration: 2000 });
          },
          () => {
            this.imageData[event.index].Directory = this.dirValue;
            this.updateData();
          })
  }

  updateData(){
    let result = {};
    result[this.view] = this.imageData;
    this.service.setData(this.tower).update(result).catch(err => {
      this.snackBar.open(err, "close", { duration: 2000 });
    })
  }

  loadMsg(){
setTimeout(()=>{this.loaded = true;},2000);
  }

  editSpinView(){

  }

  openUploadDialog(){
    let dialogRef = this.dialog.open(DialogUploadComponent, {
      height: '500px',
      width: '500px',
      disableClose: true,
      data: {
        towerName: this.towerName,
        year: this.year,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadtowerview()
    });
  }

  getData() {
    this.route.params.subscribe(params => {
      this.towerName = params.tower;
      this.year = params.year;
    });
  }

}
