import { Component, OnInit} from '@angular/core';
import { SummaryFetchService } from './summary-fetch.service'
 import { ActivatedRoute,Router } from "@angular/router";

 import { EditSummaryComponent } from './../edit-summary/edit-summary.component';
 import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
 import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {
  result;
  data;
  isLoaded : Boolean =  false;
  role;
  year;
 towerName;
 summary;
  constructor(private snackBar : MatSnackBar,private dialog: MatDialog,private service: SummaryFetchService,private route : ActivatedRoute, private router: Router) {  //,private route: ActivatedRoute
  }

  ngOnInit(): void {
    if (this.router.url.search("aero-processing") == 1) {
      this.role = "aero-processing";
    }
    if (this.router.url.search("user") == 1) {
      this.role = 'user';
    }
    if (this.router.url.search("pilot") == 1) {
      this.role = 'pilot';
    }
    if (this.router.url.search("super-admin") == 1) {
      this.role = 'super-admin';
    }
    if (this.router.url.search("telstra-processing") == 1) {
      this.role = 'telstra-processing';
    }
    this.route.params.subscribe(params =>{
      this.towerName = params.tower;this.year = params.year;
      if(this.towerName){

        this.service.fetchData(this.towerName).subscribe((res)=>{
          let temp = res.data();
          this.data =  temp['Audit'];
            this.summary = temp['Summary']
        },
        (err)=>{
          this.snackBar.open(err, "close", { duration: 2000 });
        },
        ()=>{
          this.isLoaded = true;
        })


      }
    })
  }
  editEvent(event){
    const dialogRef = this.dialog.open(EditSummaryComponent, {
      width: '450px',
      maxHeight: '850px',
      data: { value: event.value, name: event.field}
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        this.result = result;
      },
      (err) => {
        this.snackBar.open(err, "close", { duration: 2000 });
      },
      () => {
        this.updateData(event.section,event.field)
      })
  }
  updateData(section,field){
    if(this.summary == undefined || this.summary[section] === undefined){
    this.summary = {}
      let obj = {
        Status : 'N/A',
        Name : 'N/A',
        Comment : 'N/A',
        Date : 'N/A'
      }
      this.summary['SiteApproval'] = obj;
      this.summary['SiteVerification'] = obj;
      console.log(this.summary,section)
    }
    console.log(section,field)
    this.summary[section][field] = this.result;
 this.service.setData(this.towerName).set({
      Summary : this.summary
 },{
merge : true
 }).catch(err=>{
  this.snackBar.open(err, "close", { duration: 2000 });
 })
  }
}
