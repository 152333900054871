import { Component, OnInit } from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-tower-alert',
  templateUrl: './tower-alert.component.html',
  styleUrls: ['./tower-alert.component.css']
})
export class TowerAlertComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<TowerAlertComponent>) {
    dialogRef.disableClose = true;
   }

  ngOnInit(): void {
  }

}
