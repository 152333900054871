import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DigitaltwinFetchService } from '../digitaltwin-fetch.service';
import { HttpClient } from '@angular/common/http';
import { DigitaltwinComponent } from '../digitaltwin.component';

@Component({
  selector: 'app-amplitel-upload',
  templateUrl: './amplitel-upload.component.html',
  styleUrls: ['./amplitel-upload.component.css']
})
export class AmplitelUploadComponent implements OnInit {

  direclinks = []
  permLinks = []
  towerName;
  isUploading = false;

  constructor(
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DigitaltwinComponent>,
    private service: DigitaltwinFetchService,private http: HttpClient,private afs: AngularFirestore,@Inject(MAT_DIALOG_DATA) public data: {towerName: string,year: string}) { 
      this.towerName = this.data.towerName
      this.loadData()
    }

  ngOnInit(): void {
  }

  closeDialog(){
    this.dialog.closeAll()
  }

  async loadData(){
    let res = await this.service.fetchAmpList()
    let response_body = res.body
    if(!response_body.isError){
      this.direclinks = response_body['payload']
      this.permLinks = response_body['payload']
    }
  }

  async uploadData(name){
    this.isUploading = true
    let res: any = await this.service.importAmpitelUpload(this.towerName,name)
    if(!res.isError){
      let payload: any = res.payload
      this.dialogRef.close(payload)
      this.isUploading = false
    }
    else{
      this.isUploading = false
      this.snackBar.open('Amplitel Data Import Failed,Try again', 'close', {
        duration: 2000,
      });
    }
  }

  searchTowerViewFiles(value){
    if(value != '')
    {
      this.direclinks = this.permLinks.filter((val) => {
        return val.startsWith(value.toUpperCase())
      })
    }
    else{
      this.direclinks = this.permLinks
    }
    }
}
