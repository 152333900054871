<h1 style="color: #c62828;">{{data.title}}</h1>
<div mat-dialog-content>
  <!-- {{data.message}}<b>{{siteID}}</b> -->
</div>
<mat-form-field class="example-full-width" appearance="outline">
    <mat-label>SiteID</mat-label>
    <input matInput placeholder="Enter the siteID here" [(ngModel)]="siteValue">
  </mat-form-field>
<div >
  <button type="button" class="btn btn-primary" style="margin:0 10px 0 0" (click)="confirmDeleteSite()">Confirm</button>
  <button type="button" class="btn btn-secondary" [mat-dialog-close]="false">Cancel</button>
</div>
