<h1 mat-dialog-title>Search</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Inspected Towers</mat-label>
    <input  matInput type="search" list="towers" [(ngModel)]="value">
    <datalist id="towers">
      <ng-container  *ngIf="data.value.length > 0">
        <ng-container  *ngFor="let year of data.value">
          <ng-container *ngFor="let item of year.Towers">
            <option *ngIf="item.Status === 'Inspected'" [value]="year.Year+'/'+item.Tower">{{item.Tower}}</option>
          </ng-container>
        </ng-container>
      </ng-container>
    </datalist>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="searchFn()" cdkFocusInitial class="submit">Ok</button>
</div>
