import { Component, OnInit, HostListener } from '@angular/core';
import { UploadService } from './upload.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute,Router } from "@angular/router";
declare let CryptoJS:any
import { environment } from 'src/environments/environment';

import * as S3 from 'aws-sdk/clients/s3';
@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  encryptedString = localStorage.getItem('creds')
  decryptedStream = CryptoJS.AES.decrypt(this.encryptedString, environment.secret)
  credentials:any = JSON.parse(this.decryptedStream.toString(CryptoJS.enc.Utf8))
  s3 = new S3(this.credentials.accessData.uploadS3);
  uploadList;
  towerName;
  role;
  year;
  constructor(private upload: UploadService, private snackBar: MatSnackBar,private route : ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    if (this.router.url.search("aero-processing") == 1) {
      this.role = "aero-processing";
    }
    if (this.router.url.search("user") == 1) {
      this.role = 'user';
    }
    if (this.router.url.search("pilot") == 1) {
      this.role = 'pilot';
    }
    if (this.router.url.search("super-admin") == 1) {
      this.role = 'super-admin';
    }
    if (this.router.url.search("telstra-processing") == 1) {
      this.role = 'telstra-processing';
    }
    this.route.params.subscribe(params =>{
      this.towerName = params.tower;
      this.year = params.year;
    })


  }


  uploadFolders(files) {
    if(files != null){
      this.uploadList = files;
    }
      for (let i = 0; i < this.uploadList.length; i++) {
        let path = this.uploadList[i].webkitRelativePath;
        this.uploadS3(path,this.uploadList[i])
      }
this.uploadList = []

  }

  uploadS3(path,file){
            this.s3.putObject({
           Bucket: "demo-pilot-upload",
           Key: this.towerName + '/' + path,
           Body: file,
         }, (res) => {
           this.snackBar.open('SuccessFully Uploaded!', "close", { duration: 2000 });
         });
  }


  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
    this.getFilesDataTransferItems(event.dataTransfer.items)
    }
  }
  getFilesDataTransferItems(items) {
    for (let item of items){
      let temp= item.webkitGetAsEntry();
   this.findFile(temp,temp.fullPath)
    }
}

findFile(item,dir){
  if(item.isFile){
    item.file( file => {
      file.filepath = dir;
      console.log(file)
      this.uploadS3(dir.replace("/",""),file);
    });
  }
  if(item.isDirectory){
    let dirReader = item.createReader();
    dirReader.readEntries((entries) => {
      for (let entry of entries){
         this.findFile(entry,entry.fullPath);
      }
    });
  }
  }


}
