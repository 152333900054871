import { Component, OnInit } from '@angular/core';
import { DocsFetchService } from './docs-fetch.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditComponent } from './../edit/edit.component';

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.css']
})
export class DocsComponent implements OnInit {
  role;
  towerName;
  result = [];
  isLoaded = false;
 urlSafe;
 updatedValue = '';
  link = null;
  constructor(private router : Router,private dialog: MatDialog,private service: DocsFetchService,private route : ActivatedRoute,public sanitizer: DomSanitizer,private storage : AngularFireStorage,private snackBar : MatSnackBar) { }

  ngOnInit(): void {
    if (this.router.url.search("aero-processing") == 1) {
      this.role = "aero-processing";
    }
    if (this.router.url.search("user") == 1) {
      this.role = 'user';
    }
    if (this.router.url.search("pilot") == 1) {
      this.role = 'pilot';
    }
    if (this.router.url.search("super-admin") == 1) {
      this.role = 'super-admin';
    }
    if (this.router.url.search("telstra-processing") == 1) {
      this.role = 'telstra-processing';
    }
    this.route.params.subscribe(params =>{
      this.towerName = params.tower;
      if(this.towerName){

        this.service.fetchData(this.towerName).get().subscribe(
          (data)=>{
            data.forEach(elt =>{
             this.result = elt.data()['Docs'];
            })
          },
          (err)=>{
            this.snackBar.open(err, "close", { duration: 2000 });
          },
          ()=>{
            this.isLoaded = true;
          }
        )
  }
    });
  }

  updatePDF(link) {
    this.link = null;
    this.link = this.sanitizer.bypassSecurityTrustResourceUrl(link);
  }

//for adding and deleting the doc
  editDoc(event){
if(event.action == "Add"){
 for(let i = 0; i < event.file.length; i++){
   let name = event.file[i].name;
   let ref = this.storage.ref('Docs/'+this.towerName+'/'+name);
    ref.put(event.file[i]).then(()=>{
      let link = null;
      let loc = event.file[i].name;
      ref.getDownloadURL().subscribe(
        (url)=>{
          link = url;
        },
        (err)=>{
          this.snackBar.open(err, "close", { duration: 2000 });
        },
  ()=>{
    if(link != null){

      this.result.push({ File : name,Link : link })
      this.updateDoc();
    }
  })
    })


 }
}
if(event.action == "Delete"){
  for(let i = 0; i < event.files.length;i++){
    let id = null;
    this.result = this.result.filter(elt =>{
      if(elt.File == event.files[i]){
         this.storage.storage.refFromURL(elt.Link).delete().catch(err=>{
          this.snackBar.open(err, "close", { duration: 2000 });
        });
      }
     return elt.File !== event.files[i];
   });

   this.updateDoc();
  }


}
if(event.action == "Edit"){
  const dialogRef = this.dialog.open(EditComponent, {
    width: '450px',
    maxHeight: '350px',
    data: { value: event.name, name: 'Name' }
  });
  dialogRef.afterClosed().subscribe(
    result => {
      this.updatedValue = result.value;
    },
    (err) => {
      this.snackBar.open(err, "close", { duration: 2000 });
    },
    () => {
       if (this.updatedValue != '' || this.updatedValue != undefined) {
        if (this.updatedValue !== event.name) {
          this.result[event.index].File = this.updatedValue;
          this.updateDoc();
        }
        this.updatedValue = '';
      }
    }
  )
}
  }


  //for updating the doc
  updateDoc(){
    this.service.setData(this.towerName).set(
      {
      Docs : this.result
      },
      {
        merge: true
      }
    ).catch(err=>{
      this.snackBar.open(err, "close", { duration: 2000 });
    })

  }

}
