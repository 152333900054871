 <mat-chip-list aria-label="Issues selection">
  <mat-chip class="chips" value="General" [ngClass]="{'chipsSelected': item == 'General'}" (click)="selectDetails($event)">General</mat-chip>
  <mat-chip class="chips" value="Equipment" [ngClass]="{'chipsSelected': item == 'Equipment'}" (click)="selectDetails($event)">Equipment</mat-chip>
  <mat-chip *ngIf="(role == 'super-admin' || role == 'aero-processing')"class="deleteIcon" (click)="getJSON()">Get JSON<mat-icon>article</mat-icon></mat-chip>
  <ng-container *ngIf="(role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing') && selected == 'Details'">
    <mat-chip class="addIcon" (click)="addSection()"><mat-icon>add</mat-icon></mat-chip>
    <mat-chip class="deleteIcon" (click)="deleteSection()"><mat-icon>delete</mat-icon></mat-chip>
  </ng-container>
</mat-chip-list>

<div *ngIf="data" class="content">
  <div *ngIf="selected == 'General'">
    <div *ngFor="let items of data.General;index as j">
      <div *ngFor="let item of items.Details;index as i" [ngClass]="{'evenRow': 0 === i % 2, 'oddRow' : 0 !== i % 2}">
        <div class="contentBox" *ngIf="item.Option != 1 && item.Option != '' && item.Option != null">
          <div class="contentHeading">{{ items.Name }}
            <span class="detailsHeadingIcon" *ngIf="(role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing')"><i><mat-icon class="editIcon" (click)="editData('Name',items.Name,'General',i,j,true)"> edit</mat-icon></i></span>
          </div>
          <table class="table">
            <tr class="subHeading">
              <td>No</td>
              <td>Item</td>
              <td *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'">Edit</td>
              <td colspan="2">Description</td>
              <td *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'">Edit</td>
              <td>Visual</td>
              <td *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'">Edit</td>
            </tr>
            <tr class="contentRow"  *ngIf="item.Option != 1 && item.Option != '' && item.Option != null">
              <td>{{i+1}}</td>
              <td>{{ item.Name }}</td>
              <td *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'"><i><mat-icon class="editIcon" (click)="editData('Name',item.Name,'General',i,j,false)"> edit</mat-icon></i></td>
              <td colspan="2" class="boxContainer">
                <span class="roundedBox"
                  [ngClass]="{'monitor' : item.Option == 1,'planned' : item.Option == 2,'urgent' : item.Option == 3,'emergency' : item.Option == 4}">
                  {{ item.Value }}</span>
              </td>
              <td  *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'"><i><mat-icon class="editIcon" (click)="editData('Value',item.Value,'General',i,j,false)"> edit</mat-icon></i></td>
              <td>
                <i *ngIf="item.Image.length"><mat-icon class="imageIcon" (click)="sendImage(item.Image)">description</mat-icon></i>
              </td>
              <td  *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'"><i><mat-icon class="editIcon" (click)="editData('Image',item.Image,'General',i,j,false)"> edit</mat-icon></i></td>
            </tr>
          </table>

        </div>
      </div>
    </div>
  </div>
 <div *ngIf="selected == 'Equipment'">
    <div *ngFor="let items of data.Equipment;index as j">
      <div *ngFor="let item of items.Details;index as i" [ngClass]="{'evenRow': 0 === i % 2, 'oddRow' : 0 !== i % 2}">
        <div class="contentBox"  *ngIf="item.Option != 1 && item.Option != '' && item.Option != null">
          <div class="contentHeading">{{ items.Name }}</div>
          <table class="table">
            <tr class="subHeading">
              <td>No</td>
              <td>Item</td>
              <td *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'">Edit</td>
              <td colspan="2">Description</td>
              <td *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'">Edit</td>
              <td>Visual</td>
              <td *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'">Edit</td>
            </tr>
            <tr class="contentRow"  *ngIf="item.Option != 1 && item.Option != '' && item.Option != null">
              <td>{{i+1}}</td>
              <td>{{ item.Name }}</td>
              <td *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'"><i><mat-icon class="editIcon" (click)="editData('Name',item.Name,'Equipment',i,j,false)"> edit</mat-icon></i></td>
              <td colspan="2" class="boxContainer">
                <span class="roundedBox"
                  [ngClass]="{'monitor' : item.Option == 1,'planned' : item.Option == 2,'urgent' : item.Option == 3,'emergency' : item.Option == 4}">
                  {{ item.Value }}</span>
              </td>
              <td  *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'"><i><mat-icon class="editIcon" (click)="editData('Value',item.Value,'Equipment',i,j,false)"> edit</mat-icon></i></td>
              <td>
                <i *ngIf="item.Image.length"><mat-icon class="imageIcon" (click)="sendImage(item.Image)">description</mat-icon></i>
              </td>
              <td  *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'"><i><mat-icon class="editIcon" (click)="editData('Image',item.Image,'Equipment',i,j,false)"> edit</mat-icon></i></td>

            </tr>
          </table>

        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selected == 'Details'">
    <div class="contentBox">
      <div class="contentHeading">
        {{ item.Name }}
        <span class="detailsHeadingIcon" *ngIf="(role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing') && item.section === 'General'"><i><mat-icon class="editIcon" (click)="editData('Name',item.Name,item.section,i,item.sectionIndex,true)"> edit</mat-icon></i></span>
      </div>
      <table class="table">
        <tr class="subHeading">
          <td>No</td>
          <td>Item</td>
          <td *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'">Edit</td>
          <td colspan="2">Description</td>
          <td *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'">Edit</td>
          <td>Visual</td>
          <td *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'">Edit</td>
          <td *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'"><p *ngIf="mode == 'Delete'">Delete</td>
        </tr>
        <tr class="contentRow" *ngFor="let elt of item.Details;index as i" [ngClass]="{'evenRow': 0 === i % 2, 'oddRow' : 0 !== i % 2}">
            <td>{{i+1}}</td>
            <td>{{ elt.Name }}</td>
            <td *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'"><i><mat-icon class="editIcon" (click)="editData('Name',elt.Name,item.section,i,item.sectionIndex,false,item.Name)"> edit</mat-icon></i></td>
            <td colspan="2" class="boxContainer">
              <span class="roundedBox" [ngClass]="{'monitor' : elt.Option == 1,'planned' : elt.Option == 2,'urgent' : elt.Option == 3,'emergency' : elt.Option == 4}">
                {{ elt.Value }}</span>
            </td>
            <td  *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'"><i><mat-icon class="editIcon" (click)="editData('Value',elt.Value,item.section,i,item.sectionIndex,false,item.Name)"> edit</mat-icon></i></td>
            <td>
              <i *ngIf="elt.Image.length"><mat-icon class="imageIcon" (click)="sendImage(elt.Image)">description</mat-icon></i>
            </td>
            <td *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'"><i><mat-icon class="editIcon" (click)="editData('Image',elt.Image,item.section,i,item.sectionIndex,false,item.Name)"> edit</mat-icon></i></td>
            <td  *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'"><p *ngIf="mode == 'Delete' && i != 0 && i != 1"><span class="delete"><input type="checkbox" (change)="deleteItems($event,elt.Name,item.section,i,item.sectionIndex,item.name)"></span></p></td>

          </tr>
      </table>


    </div>
  </div>
</div>
