import { Component, OnInit, ViewChild } from '@angular/core';
import { TableFetchService } from './table-fetch.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { MatTableDataSource } from '@angular/material/table';
import { EditComponent } from './../edit/edit.component'
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TowerComponent } from './../tower/tower.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ProcessDialogComponent } from './process-dialog/process-dialog.component'
import firebase from '@firebase/app';
import '@firebase/firestore';
import { filter } from 'rxjs/operators';

import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

export interface dataTable {
  Tower: string,
  Type: string,
  SiteID: number,
  Region: string,
  Status: string,
  Completed: string,
  Latitude: number,
  Longitude: number,
  Corrosion: string,
  StructureType: string,
  CanradVariation: string,
  Emergency: number,
  Urgent: number,
  Planned: number,
  Monitor: number
}


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {
  displayedColumns: string[] = ['No', 'Tower', 'TowerType', 'SiteID', 'Region', 'Status', 'Completed', 'Latitude', 'Longitude', 'Corrosion', 'StructureType', 'CanradVariation', 'Processed', 'Emergency', 'Urgent', 'Planned', 'Monitor'];//
  data = [];
  dataSource: MatTableDataSource<dataTable> = new MatTableDataSource();
  year = ['2021', '2022', '2023']
  crntYear = 0;
  role;
  selectedStatus = '';
  isLoaded = false;
  summariesCSV;
  filterPredicate;
  detailsCSV;
  count = 0;
  towerName;
  result;
  isProcessing = false;
  totalPrg = {
    currentTowers: 0,
    totalTowers: 0,
    percentProcessed: 0,
  }
  processedStatus = ['T', 'D', 'O', 'N/A', 'RESET']
  workbook = new Workbook()
  workSheet = this.workbook.addWorksheet('Summaries');
  @ViewChild(MatSort, { static: false })
  set sort(sorter: MatSort) {
    if (sorter) {
      this.dataSource.sort = sorter;
    }
  }
  @ViewChild(MatPaginator, { static: false })
  set paginator(pager: MatPaginator) {
    if (pager) {
      this.dataSource.paginator = pager;
      this.dataSource.data = this.data[this.crntYear]['Towers']
    }
  }
  @ViewChild('status') status;
  @ViewChild(MatTable) table: MatTable<any>;

  constructor(private service: TableFetchService, private dialog: MatDialog, private router: Router, private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    if (this.router.url.search("aero-processing") == 1) {
      this.role = "aero-processing";
    }
    if (this.router.url.search("user") == 1) {
      this.role = 'user';
    }
    if (this.router.url.search("pilot") == 1) {
      this.role = 'pilot';
    }
    if (this.router.url.search("super-admin") == 1) {
      this.role = 'super-admin';
    }
    if (this.router.url.search("telstra-processing") == 1) {
      this.role = 'telstra-processing';
    }
    console.log(this.role);
  }

  ngAfterViewInit() {
    this.fetchTowers(this.year[this.crntYear])
  }

  adjustColumnWidth(worksheet) {
    worksheet.columns.forEach(column => {
      const lengths = column.values.map(v => v.toString().length);
      const maxLength = Math.max(...lengths.filter(v => typeof v === 'number')) + 2;
      column.width = maxLength;
    });
  }

  fetchTowers(year) {
    this.service.fetchTowers('TOWERS' + "/" + year + "/" + year).forEach(data => {
      let towerList = []
      data.forEach(doc => {
        let data: any = doc.data()
        towerList = [...towerList, data]
      })
      // console.log(towerList) // fetching tower data
      this.data[this.crntYear] = { Towers: towerList, Year: year };
    }).then(() => {
      this.isLoaded = true;
      this.filterPredicate = this.dataSource.filterPredicate;
      this.workSheet.addRow(['Tower', 'TowerType', 'SiteID', 'Region', 'Status', 'Completed', 'Latitude', 'Longitude', 'Corrosion', 'Structure Type', 'Canrad Variation', 'Processed', 'Emergency', 'Urgent', 'Planned', 'Monitor'])
      for (let j = 0; j < this.data[this.crntYear]['Towers'].length; j++) {
        this.workSheet.addRow([this.data[this.crntYear]['Towers'][j]["Tower"], this.data[this.crntYear]['Towers'][j]["Type"], this.data[this.crntYear]['Towers'][j]["SiteID"], this.data[this.crntYear]['Towers'][j]["Region"], this.data[this.crntYear]['Towers'][j]["Status"], this.data[this.crntYear]['Towers'][j]["Completed"], this.data[this.crntYear]['Towers'][j]["Latitude"], this.data[this.crntYear]['Towers'][j]["Longitude"], this.data[this.crntYear]['Towers'][j]["Corrosion"], this.data[this.crntYear]['Towers'][j]["StructureType"], this.data[this.crntYear]['Towers'][j]["CanradVariation"], this.data[this.crntYear]['Towers'][j]["processed"], this.data[this.crntYear]['Towers'][j]["Emergency"], this.data[this.crntYear]['Towers'][j]["Urgent"], this.data[this.crntYear]['Towers'][j]["Planned"], this.data[this.crntYear]['Towers'][j]["Monitor"]]);
      }
      this.adjustColumnWidth(this.workSheet)
    })
  }

  switchStatus(event) {
    console.log(event.target.value)
    // this.sort.sort(({ id: 'Status' }) as MatSortable);
    // this.sort.direction = event.target.value;
    // this.sort.disableClear = false;
    // this.dataSource.sort = this.sort;
    this.dataSource.filter = event.target.value
  }

  inspectedList(elt) {
    return elt.Status === 'Inspected';
  }

  unInspectedList(elt) {
    return elt.Status === 'Uninspected';
  }

  switchTable() {
    this.isLoaded = false;
    this.fetchTowers(this.year[this.crntYear])
    this.workbook.removeWorksheet("Summaries")
    this.workSheet = this.workbook.addWorksheet('Summaries');
  }

  // downloadCSV(csv, Name) {
  //   let downloadLink = document.createElement('a');
  //   downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
  //   downloadLink.target = '_blank';
  //   downloadLink.download = Name + '.csv';
  //   downloadLink.click();
  // }

  downloadCSV(name) {
    let today = name + '_' + this.data[this.crntYear].Year + '_' + Math.round(Date.now() / 1000)
    this.workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, today + '.xlsx');
    });
  }

  getTotal(issue) {
    this.count = 0;
    this.data[this.crntYear]['Towers'].forEach(record => {
      if (record[issue]) {
        this.count += parseInt(record[issue])
      }
    });
    return this.count;
  }

  applyStatusFilter() {
    //  this.dataSource.filterPredicate = this.filterPredicate;

    let filterValue = this.selectedStatus;
    this.dataSource.filter = filterValue;
    this.status.nativeElement.click();
    if (this.dataSource.paginator) {
      if (this.dataSource.filter === 'inspected') {

      }
      this.dataSource.paginator.firstPage();

    }
  }

  setupFilter(column: string) {
    if (column == 'processed') {
      this.dataSource.filterPredicate = (d: any, filter: string) => {
        const textToSearch = d[column] && d[column].toUpperCase() || '';
        return textToSearch.indexOf(filter) !== -1;
      };
    }
    else if (column == 'Status') {
      this.dataSource.filterPredicate = (d: any, filter: string) => {
        if (filter != '') {
          const textToSearch = d[column];
          return textToSearch == filter
        }
        else {
          return true
        }
      };
    }
  }

  applyProcessedStatusFilter(filterValue: string) {
    if (filterValue != 'RESET') {
      this.dataSource.filter = filterValue.trim().toUpperCase();
    }
    else {
      this.dataSource.filter = "";
    }
  }

  applyFilter(event: Event) {

    // this.dataSource.filterPredicate =
    // (data: dataTable, filter: string) => !filter || data.Status == filter;

    let filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();
    this.status.nativeElement.click();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  clickEvent(value, event, condition, id, field, elm) {
    // console.log('value, event, condition, id, field- ', value);
    // console.log('value, event, condition, id, field- ', event);
    // console.log('value, event, condition, id, field- ', condition);
    // console.log('value, event, condition, id, field- ', id);
    // console.log('value, event, condition, id, field- ', field);
    // console.log('value, event, condition, id, field- ', elm);

    event.stopPropagation();
    if (condition == false) {
      if (this.role != 'user' && id == 'Inspected') {
        this.router.navigate(['/' + this.role + '/' + this.data[this.crntYear].Year + '/' + value.replaceAll(' ', '') + '/summary']);
      }
      else if (this.role == 'user' && id == 'Inspected' && elm.Completed == 'Yes') {
        this.router.navigate(['/' + this.role + '/' + this.data[this.crntYear].Year + '/' + value.replaceAll(' ', '') + '/summary']);
      }
      // if (id == 'Inspected' && this.role == 'user') {
      //   this.router.navigate(['/' + this.role + '/' + this.data[this.crntYear].Year + '/' + value.replaceAll(' ', '') + '/summary']);
      // } 
      // if (id == 'Inspected' && this.role != 'user') {
      //   this.router.navigate(['/' + this.role + '/' + this.data[this.crntYear].Year + '/' + value.replaceAll(' ', '') + '/summary']);
      // } 

      else {
        this.snackBar.open('This Site is not ready yet!', "close", { duration: 2000 });
      }
    }
    if (condition == true && id != null) {
      const dialogRef = this.dialog.open(EditComponent, {
        width: '300px',
        data: { value: value, name: field }
      });
      dialogRef.afterClosed().subscribe(
        result => {
          this.result = result.value;
        },
        (err) => {
          this.snackBar.open(err, "close", { duration: 2000 });
        },
        () => {
          this.service.setData(this.year[this.crntYear], elm['Tower'].replace(/\s/g, '') + "__" + elm['SiteID']).set(
            { ...elm, [field]: this.result, "updatedAt": firebase.firestore.FieldValue.serverTimestamp() },
            {
              merge: true
            }
          ).then(() => {
            this.data[this.crntYear]['Towers'].forEach(elt => {
              if (elt.SiteID == id) {

                elt[field] = this.result;
              }
            });
            this.snackBar.open(`${elm['Tower'].replace(/\s/g, '')} tower edited successfully!`, "close", { duration: 2000 });
            // this.switchTable()
          });
        }
      );
    }
  }

  editTower(towerName, sideID, mode, event) {
    console.log(mode)
    event?.stopPropagation();
    if (mode === 'add') {
      const dialogRef = this.dialog.open(TowerComponent, {
        width: '450px',
        maxHeight: '90vh',
        data: {
          TowerDatas: this.data[this.crntYear].Towers,
        }
      });
      dialogRef.afterClosed().subscribe(
        (result) => {
          if (result) {
            this.result = result;
          }
        },
        (err) => {
          this.snackBar.open(err, "close", { duration: 2000 });
        },
        () => {
          if (this.result !== null && Object.keys(this.result).length === 0 && this.result.constructor === Object) {
            this.snackBar.open("Duplicate Tower or SiteID Entry", "close", { duration: 2000 });
          }
          else {
            console.log('this.result - ', this.result);

            this.result.TowersData["createdAt"] = firebase.firestore.FieldValue.serverTimestamp()
            this.result.TowersData["updatedAt"] = firebase.firestore.FieldValue.serverTimestamp()
            this.service.setData(this.data[this.crntYear].Year, this.result.TowersData['Tower'].replace(/\s/g, '') + "__" + this.result.TowersData['SiteID']).set(this.result.TowersData).then(() => {
              if (!this.result.useOldData) {
                let addTwrToDb = (this.result.TowersData.Tower).replaceAll(/\s/g, '');
                console.log('done! 1 - ', addTwrToDb);
                this.service.addTower(addTwrToDb).set(this.result.Tower)
                  .then(() => {
                    console.log('done! 2');
                    this.dataSource.data = [...this.dataSource.data, this.result.TowersData];
                    this.table.renderRows();
                    this.data[this.crntYear]['Towers'].push(this.result.TowersData);
                    this.snackBar.open(`${this.result.TowersData['Tower'].replace(/\s/g, '')} tower added successfully!`, "close", { duration: 2000 });
                  })
                  .catch((err) => {
                    this.snackBar.open(err, "close", { duration: 2000 });
                  })
              }
              else {
                this.dataSource.data = [...this.dataSource.data, this.result.TowersData];
                this.table.renderRows();
                this.data[this.crntYear]['Towers'].push(this.result.TowersData);
                this.snackBar.open(`${this.result.TowersData['Tower'].replace(/\s/g, '')} tower added successfully!`, "close", { duration: 2000 });
              }
            }).catch((err) => {
              this.snackBar.open(err, "close", { duration: 2000 });
            })

          }
        });
    }
    else if (mode === 'delete') {
      // -------------------------have undo later--------------------
      console.log("Delete operation called...")
      let dialogRef = this.dialog.open(ConfirmDialogComponent, {
        height: 'auto',
        width: 'auto',
        data: {
          title: "Delete this site?",
          message: "Confirm you want to delete this site by typing its ID:",
          siteID: sideID,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result.ok) {
          this.service.setData(this.data[this.crntYear].Year, towerName.replace(/\s/g, "") + "__" + result.siteValue).delete().then((res) => {
            console.log('delete res', res);
            this.data[this.crntYear]['Towers'] = this.data[this.crntYear]['Towers'].filter(elt => {
              return elt.SiteID != sideID
            });
            this.snackBar.open(`${towerName.replace('\/s\g', "")} tower deleted successfully!`, "close", { duration: 2000 });
            this.table.renderRows();
            this.dataSource.data = this.data[this.crntYear]['Towers']
          }).catch((err) => {
            this.snackBar.open(err, "close", { duration: 2000 });
          })
        }
      });
    }
  }

  getProcessedStatus(towerName, event) {
    event.stopPropagation();
    var processedStatus = ''
    this.service.fetchData(towerName).subscribe((res) => {
      let temp = res.data();
      if (temp != undefined) {
        let towerView = temp['TowerView']
        if (towerView.length > 0) {
          processedStatus = "T"
        }
        let dg = temp['DigitalTwin'];
        if (dg.Link != '') {
          processedStatus = processedStatus + "/D"
        }
        let ortho = temp['OrthoMap']
        if (JSON.stringify(ortho) != '{}') {
          processedStatus = processedStatus + "/O"
        }
        if (processedStatus == "") {
          event.target.parentElement.innerHTML = "None"
        }
        else {
          event.target.parentElement.innerHTML = processedStatus
        }
      }
      else {
        this.snackBar.open("Tower data not available", "close", { duration: 2000 });
      }
    },
      (err) => {
        this.snackBar.open(err, "close", { duration: 2000 });
      },
      () => {
        this.isLoaded = true;
      })
  }


  openProcessedDialog() {
    let dialogRef = this.dialog.open(ProcessDialogComponent, {
      height: 'auto',
      width: '600px',
      data: {
        data: this.data,
        yearIdex: this.crntYear
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  async getProcessedStatuss() {
    this.isProcessing = true
    var percentToUpdate = 100 / this.data[this.crntYear]['Towers'].length
    var percentGoing = 0
    this.totalPrg.totalTowers = this.data[this.crntYear]['Towers'].length
    for (var i = 0; i < this.data[this.crntYear]['Towers'].length; i++) {
      try {
        let res: any = await this.service.fetchData(this.data[this.crntYear]['Towers'][i].Tower.replace(/\s+/g, '')).toPromise()
        let processedStatus = ''
        let temp = res.data();
        if (temp != undefined) {
          let towerView = temp['TowerView']
          if (towerView.length > 0) {
            processedStatus = processedStatus + "T"
          }
          let dg = temp['DigitalTwin'];
          if (dg.Link != '') {
            processedStatus = "D"
          }
          let ortho = temp['OrthoMap']
          if (JSON.stringify(ortho) != '{}') {
            processedStatus = processedStatus + "O"
          }
          if (processedStatus == "") {
            this.data[this.crntYear]['Towers'][i].processed = processedStatus
          }
          else {
            this.data[this.crntYear]['Towers'][i].processed = processedStatus
          }
        }
        else {
          this.data[this.crntYear]['Towers'][i].processed = "-"
          this.snackBar.open("Tower data not available", "close", { duration: 2000 });
        }
        // await this.service.setData(this.data[this.crntYear].Year).set(
        //   this.data[this.crntYear],
        //   {
        //     merge: true
        //   }
        // );
        percentGoing = percentGoing + percentToUpdate
        this.totalPrg.percentProcessed = Math.floor(percentGoing)
        this.totalPrg.currentTowers = i + 1
      }
      catch (err) {
        console.log(err.message)
      }
    }
    this.isProcessing = false
    this.snackBar.open("Show Processed data completed", "close", { duration: 2000 });
  }
}
