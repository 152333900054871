
<div class="navBar">
  <app-navbar [main]="false"></app-navbar>
</div>

<div class="summary">
  <div class="uploadTabs">
    <!-- <input type="file" (change)="uploadFolders($event.target.files)" accept="image/*" webkitdirectory directory multiple> -->

    <mat-card draggable="true" class="dragarea">
      <div class="row">
          <div class="col-md-12 text-center">
              <span class="choose" (click)="file.click()">
                 Choose
              </span>
              or
              Drag Folders with Images here <br>
              <span class="sub">Folder will be Uploaded to S3 Bucket with same Path</span>
              <input #file type="file"
                   accept="image/*"
                   webkitdirectory
                   directory
                   multiple
                     (change)="uploadFolders($event.target.files)"
                     style="display:none" />
          <div class="dragged-files-wrapper" *ngIf="uploadList">
            <div class="file" *ngFor="let file of draggedFiles">
              {{file}}
            </div>
          </div>
          </div>
          </div>
      </mat-card>
  </div>
</div>


