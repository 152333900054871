<div class="file-manager-wrapper d-flex flex-column">
    <div class="d-flex align-items-center mb-2">
        <h2 class="mb-0">Upload Site</h2>
        <p style="margin-left : auto; cursor: pointer;" (click)="closeDialog()">X</p>
    </div>
    <div class="main">
        <div class="d-flex flex-column">
            <p class="text-center" *ngIf="isUploading" style="margin-bottom: 0;">{{ progressDisplay.scene }} %</p>
            <mat-progress-bar *ngIf="isUploading" mode="determinate" value="{{progressDisplay.scene}}" style="height:10px;border-radius:5px;"></mat-progress-bar>
            <div class="upload-btn pb-2" style="margin-top: auto;">
                <input type="file" id="scene-file" hidden (change)="inputFileChange($event,'scene-file')"/>
                <label for="scene-file">Choose Scene.glb</label>
                <span class="scene-file">No file chosen</span>
            </div>
        </div>

        <div class="d-flex flex-column" >
            <p class="text-center mb-0" *ngIf="isUploading">{{ progressDisplay.scene_low }} %</p>
            <mat-progress-bar mode="determinate" *ngIf="isUploading" value="{{progressDisplay.scene_low}}" style="height:10px;border-radius:5px;"></mat-progress-bar>
            <div class="upload-btn pb-2" style="margin-top: auto;">
                <input type="file" id="scene-low-file" hidden (change)="inputFileChange($event,'scene-low-file')"/>
                <label for="scene-low-file">Choose Scene_low.glb</label>
                <span class="scene-low-file">No file chosen</span>
            </div>
        </div>

        <div class="d-flex align-items-center mt-3">
            <button class="btn btn-primary mx-auto text-center" [disabled]="isUploading" (click)='upload()'>Upload</button>
        </div>
    </div>
</div>