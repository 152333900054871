import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

  title: string;
  message: string;
  siteID: string;
  siteValue: string;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private dialog: MatDialog,private snackBar: MatSnackBar) {
           this.title = this.data.title;
           this.message = this.data.message;
           this.siteID = this.data.siteID
     }

  ngOnInit(): void {
  }

  confirmDeleteSite(){
    // if(this.siteValue == this.siteID)
    if(true)
    {
      this.dialogRef.close({ok:true, siteValue:this.siteValue})
      // this.dialogRef.close(true)
    }
    else{
      this.snackBar.open('Wrong Input ID!', "close", { duration: 2000 });
    }
  }
}
