

<div class="navBar">
  <app-navbar [main]="false"></app-navbar>
</div>
<div class="spin" style="position:relative;">

  <div class="buttons editSpinView" *ngIf="role == 'aero-processing' || role == 'super-admin'">
    <button mat-raised-button color="primary" (click)="openUploadDialog()">Edit</button>
  </div>
   <div #content class="viewContainer" *ngIf="imageData.length;else ElseBlock;">
    <app-image-view [role]="role" [loading]="loading" [tower]="tower" [view]="view" [payload]="imageData" [crntDir]="crntDir" [index]="index" (editDirEvent)="editDir($event)" (imageViewEvent)="setCrntDir($event)" (imageSliderEvent)="sendIndex($event)"></app-image-view>
 <app-spin-controls [view]="view" [loading]="loading" [payload]="imageData"  [crntDir]="crntDir" [index]="index" [sliderIndex]="sliderIndex" (spinControlsEvent)="sendIndex($event)"></app-spin-controls>
  </div>
  <ng-template class="viewContainer" #ElseBlock>
    <div class="else">
      {{ loadMsg() }}
      <h1 *ngIf="loaded == true">No Data Found</h1>
    </div>
  </ng-template>
</div>
