import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OrthomapFetchService } from '../../orthomap-fetch.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  rWidth: any;
  rHeight: any;

  constructor(private http: HttpClient,private service: OrthomapFetchService) {
   }

  sendChunk(chunk: any, offset: any, index: any,tc: any,filename: any ) {
    return new Promise((resolve, reject) => {
    const APIurl = 'https://api1.phicode.co/convertion';
    const formData = new FormData();
    formData.append('image',chunk)
    formData.append('chunkcount',index)
    formData.append('chunkoffset',offset)
    formData.append('filename',filename)
    
    formData.append('tc',tc)
    return this.http.post(APIurl, formData, {responseType: 'json'}).subscribe(res => {
        resolve(res)
    });
   })
  }
   startProcessing(imagepath: string,xc: any, yc: any, cxc: any,cyc: any, rad: any){
    return new Promise((resolve, reject) => {
    const APIurl = '/upload';
    const formData = new FormData();
    formData.append('imagepath',imagepath)
    formData.append('xc',xc)
    formData.append('yc',yc)
    formData.append('cxc',cxc)
    formData.append('cyc',cyc)
    formData.append('rad',rad)
    return this.http.post(APIurl, formData, {responseType: 'json'}).subscribe(res => {
      resolve(res)
    });
    })
   }
}
