import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';
import { GalleryFetchService } from "./gallery-fetch.service";
import { AddDirComponent } from '../add-dir/add-dir.component';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { EditComponent } from './../edit/edit.component';

import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
declare let CryptoJS:any
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  encryptedString = localStorage.getItem('creds')
  decryptedStream = CryptoJS.AES.decrypt(this.encryptedString, environment.secret)
  credentials:any = JSON.parse(this.decryptedStream.toString(CryptoJS.enc.Utf8))
  s3 = new S3(this.credentials.accessData.galleryS3);

  @ViewChild("content") content;
  tower: string;
  role;
  dirLink = null;
  view: string;
  imageData = [];
  prevDir = 0;
  crntDir = 0;
  index = 0;
  sliderIndex = false;
  loading = false;
  result;
  data;
  imageDir = [];
  dirValue;
  isLoaded = false;
  constructor(private fireStore: AngularFirestore, private dialog: MatDialog, private route: ActivatedRoute, private service: GalleryFetchService, private renderer: Renderer2, private snackBar: MatSnackBar, private router: Router) {
    // console.log("s3: ", this.credentials)
    if (this.router.url.search("aero-processing") == 1) {
      this.role = "aero-processing";
    }
    if (this.router.url.search("user") == 1) {
      this.role = 'user';
    }
    if (this.router.url.search("pilot") == 1) {
      this.role = 'pilot';
    }
    if (this.router.url.search("super-admin") == 1) {
      this.role = 'super-admin';
    }
    if (this.router.url.search("telstra-processing") == 1) {
      this.role = 'telstra-processing';
    }

    this.route.params.subscribe(params => {
      this.tower = params.tower;
      this.getTower();
    })

  }

  //fectch specific towers gallery data
  getTower() {
    this.isLoaded = false;
    this.index = 0;
    this.service.fetchData(this.tower).subscribe(
      (result) => {
        let temp = {};
        temp = result.data();
        if (temp['Gallery'] != undefined) {
          this.imageData = temp['Gallery'];
        } else {
          this.imageData = [];
        }
      },
      (err) => {
        this.snackBar.open(err, "close", { duration: 2000 });
      },
      () => {
        if (this.imageData.length > 0) {
          this.isLoaded = true;
        }
      })
  }


  ngOnInit(): void {
  }

  //for focusing the selected directory
  setCrntDir(event) {
    this.crntDir = event.crntDir;
    this.index = event.contentLoadedEvent;

  }
  sendIndex(event) {
    this.sliderIndex = event.sliderIndex;
    this.index = event.index;
  }


//for adding directory in gallery
  addDir(event) {
    const dialogRef = this.dialog.open(AddDirComponent, {
      width: '500px',
      maxHeight: '480px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result == null || result == undefined) { return; }
        this.dirLink = result;
      },
      err => {
        this.snackBar.open(err, "close", { duration: 2000 });
      },
      async () => {
        let temp = this.imageData;
        let dirName = this.dirLink ? this.dirLink.split('/')[3] : null;
        let firestore = this.fireStore.collection(this.tower).doc(this.tower);
        var bucketParams = {
          Bucket: "aerodyne-telstra-dev",
          Prefix: this.dirLink
        };
        this.s3.listObjects(bucketParams, function (err, data) {
          if (err) {
            this.snackBar.open(err, "close", { duration: 2000 });
          }
          this.imageDir = data.Contents;
          let arr = []
          if (this.imageDir.length > 0) {
            for (var i = 0; i < this.imageDir.length; i++) {
              let key = this.imageDir[i].Key.split(".")[1];
              if(key == "jpg" || key == "jpeg" || key == "JPG" || key == "JPEG"){
                arr.push({ file: "https://aerodyne-telstra-dev.s3-ap-southeast-2.amazonaws.com/" + this.imageDir[i].Key })
              }
            }
            this.imageData = temp;
            this.imageData.push({
              Directory: dirName,
              Payload: arr
            });


            if (arr.length > 0 && dirName != '') {
              firestore.update({
                Gallery: this.imageData
              }).catch(err => {
                this.snackBar.open(err, "close", { duration: 2000 });
              })
            }else{
              this.snackBar.open('ERROR!', "close", { duration: 2000 });
            }

            // firestore.update({
            //   Gallery: this.imageData
            // }).catch(err => {
            //   this.snackBar.open(err, "close", { duration: 2000 });
            // })

          }
          this.dirLink = null;
        });

      }
    )
  }

//edit gallery directory
  editDir(event) {
    console.log(event);
    const dialogRef = this.dialog.open(EditComponent, {
      width: '450px',
      maxHeight: '480px',
      data: { name: "Directory", value: event.value }
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        this.dirValue = result.value;
      },
      err => {
        this.snackBar.open(err, "close", { duration: 2000 });
      },
      () => {
        this.imageData[event.index].Directory = this.dirValue;
        this.updateData();
      })
  }

  //for updating the gallery
  updateData() {
    this.service.setData(this.tower).update({
      Gallery: this.imageData
    }).catch(err => {
      this.snackBar.open(err, "close", { duration: 2000 });
    })
  }

  //deleting the directory
  deleteDir(event) {
    event.forEach(data => {
      this.imageData = this.imageData.filter(elt => {
        return (elt.Directory === data.name) ? false : true;
      });
      console.log(event)
      this.updateData();

    });
  }

}
